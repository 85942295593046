import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    VStack,
    Input,
    FormControl,
    FormLabel,
    Textarea,
    useToast,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import { updatePSCLocation } from '../../api';

const EditPSCLocationModal = ({ isOpen, onClose, locationData, onSuccess }) => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        hoursOfOperation: '',
    });
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [error, setError] = useState(null);
    const toast = useToast();

    // Populate form data when modal opens
    useEffect(() => {
        if (locationData) {
            setFormData({
                name: locationData.name,
                address: locationData.address,
                hoursOfOperation: locationData.hoursOfOperation,
            });
        }
    }, [locationData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };




    const handleUpdatePSCLocation = async () => {
        try {
            if (!formData.name || !formData.address || !formData.hoursOfOperation) {
                setError('All fields are required');
                return;
            }

            setLoadingSubmit(true);

            const result = await updatePSCLocation(locationData._id, formData);
            if (result.success) {
                onSuccess(result.data)
                toast({
                    title: 'PSC Location Updated Successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose()
            } else {
                setError(result.error)
            }
        } catch (error) {
            console.error('Error updating PSC location:', error);
        }

        setLoadingSubmit(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit PSC Location</ModalHeader>
                <ModalCloseButton />
                {error && <Alert status='error'><AlertIcon /><AlertDescription>{error}</AlertDescription></Alert>}
                <ModalBody>
                    <VStack spacing={4} align="stretch">
                        <FormControl isRequired>
                            <FormLabel>Location Name</FormLabel>
                            <Input
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter Location Name"
                            />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel>Location Address</FormLabel>
                            <Input
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder="Enter Location Address"
                            />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel>Hours of Operation</FormLabel>
                            <small>To add new line use  ,  Where you want to add new line</small>
                            <Textarea
                                name="hoursOfOperation"
                                value={formData.hoursOfOperation}
                                onChange={handleChange}
                                placeholder="Enter Hours of Operation (e.g. Monday - Friday, 8:30AM - 4:30PM)"
                            />
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} isLoading={loadingSubmit} onClick={handleUpdatePSCLocation}>
                        Save Changes
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditPSCLocationModal;
