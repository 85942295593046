import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Textarea,
    Alert,
    AlertIcon,
    AlertDescription,
    SimpleGrid,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { fetchFacilitiesBySearch, getAllFacilitiesOutside, createPickup } from '../../api'; // Add necessary API functions
import AsyncSelect from 'react-select/async';
import { alert } from '../../Utils';


const CreateTempPickupModal = ({ isOpen, onClose }) => {
    const [pickupLocation, setPickupLocation] = useState('');
    const [note, setNote] = useState('');
    const [facilities, setFacilities] = useState([]);

    const [loading, setLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(null);

    useEffect(() => {
        getFacilities();
    }, []);

    const getFacilities = async () => {
        try {
            const response = await getAllFacilitiesOutside();
            if (response.success) {
                setFacilities(response.data.facilities);
            }
        } catch (error) {
            setErrorModal('Error loading facilities');
        }
    };


    const handleCreatePickup = async () => {

        setLoading(true)

        const data = {
            type: 'Default',
            pickupLocation,
            dropOffLocation: "670d9a4ee15760c0f5c1d70e",
            status: "TEMP"
        }

        if (note) {
            data.noteText = note
        }

        const response = await createPickup(data)
        if (response.success) {
            alert("Great", "success", "Pickup was created successfully", "Okay !");
            onClose()
        } else {
            setErrorModal(response.error);
        }

        setLoading(false)
    };







    return (
        <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Pickup</ModalHeader>
                <ModalBody>
                    {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                    <SimpleGrid mt={5} mb={3} columns={1} spacing={4}>

                        <>
                            <FormControl mb={3} isRequired>
                                <FormLabel>Pickup location</FormLabel>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={fetchFacilitiesBySearch}
                                    defaultOptions
                                    onChange={(option) => setPickupLocation(option ? option.value : '')}
                                    value={pickupLocation
                                        ? { value: pickupLocation, label: facilities.find(fac => fac._id === pickupLocation)?.name }
                                        : null}
                                    placeholder="Search and select a facility"
                                    closeMenuOnSelect={true}
                                    isClearable
                                />
                            </FormControl>
                        </>

                    </SimpleGrid>

                    <FormControl mt={4} mb={3}>
                        <FormLabel>Note</FormLabel>
                        <Textarea
                            placeholder="Add any notes"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button isLoading={loading} colorScheme="blue" onClick={handleCreatePickup}>Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default CreateTempPickupModal;
