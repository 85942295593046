import React, { useState, useEffect } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
    Button, Input, Textarea, Select, FormControl, FormLabel, Checkbox, useToast
} from "@chakra-ui/react";
import { fetchAllCouriers, sendMessage } from "../../api";

const SendMessageModal = ({ isOpen, onClose }) => {
    const [couriers, setCouriers] = useState([]);
    const [formData, setFormData] = useState({ courierId: "", message: "", image: null, priority: "normal", toAll: false });
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    useEffect(() => {
        fetchCouriers();
    }, []);

    const fetchCouriers = async () => {
        try {
            const response = await fetchAllCouriers(1, 999);
            if (response.success) {
                setCouriers(response.data.couriers);
            }
        } catch (error) {
            toast({ title: "Error loading couriers", status: "error" });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, toAll: e.target.checked, courierId: e.target.checked ? "" : formData.courierId });
    };

    useEffect(() => {
        console.log("=====NEW FORM DATA==========")
        console.log(formData)
        console.log("=====NEW FORM DATA==========")
    }, [formData])



    const handleSubmit = async () => {
        setLoading(true);
        try {
            const payload = {
                courierId: formData.toAll ? null : formData.courierId, // ✅ If toAll is checked, courierId should be null
                message: formData.message || "",
                priority: formData.priority || "normal",
                toAll: Boolean(formData.toAll), // ✅ Ensure boolean
            };

            await sendMessage(payload);
            toast({ title: "Message sent!", status: "success" });
            onClose();
        } catch (error) {
            toast({ title: "Failed to send message", status: "error" });
        }
        setLoading(false);
    };



    // const handleSubmit = async () => {
    //     setLoading(true);
    //     try {
    //         const payload = { ...formData, toAll: !!formData.toAll }; // ✅ Force boolean
    //         await sendMessage(payload);
    //         toast({ title: "Message sent!", status: "success" });
    //         onClose();
    //     } catch (error) {
    //         toast({ title: "Failed to send message", status: "error" });
    //     }
    //     setLoading(false);
    // };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Send Message</ModalHeader>
                <ModalBody>
                    <FormControl mb={3}>
                        <Checkbox isChecked={formData.toAll} onChange={handleCheckboxChange}>
                            Send to All Couriers
                        </Checkbox>
                    </FormControl>

                    <FormControl isRequired isDisabled={formData.toAll}>
                        <FormLabel>Courier</FormLabel>
                        <Select name="courierId" onChange={handleChange} placeholder="Select Courier" value={formData.courierId}>
                            {couriers?.map((c) => (
                                <option key={c._id} value={c._id}>{c.name}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mt={3} isRequired>
                        <FormLabel>Message</FormLabel>
                        <Textarea name="message" onChange={handleChange} placeholder="Type your message..." />
                    </FormControl>
                    {/* 
                    <FormControl mt={3}>
                        <FormLabel>Attach Image</FormLabel>
                        <Input type="file" onChange={handleImageChange} />
                    </FormControl> */}

                    <FormControl mt={3}>
                        <FormLabel>Priority</FormLabel>
                        <Select name="priority" onChange={handleChange} value={formData.priority}>
                            <option value="normal">Normal</option>
                            <option value="urgent">Urgent</option>
                        </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button colorScheme="blue" onClick={handleSubmit} isLoading={loading}>Send</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SendMessageModal;
