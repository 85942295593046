import React, { useState, useEffect } from "react";
import {
    Box, Table, Thead, Tbody, Tr, Th, Td, Button, Input, Spinner, useToast, Badge
} from "@chakra-ui/react";
import { getShipments, markItemReturned, addShipmentNote } from "../../api";
import AccessDenied from "../../components/AccessDenied";
import { hasPermission, trimText } from "../../Utils";
import TitleHeader from "../../components/TitleHeader";
import AddShipmentModal from "./AddShipmentModal";
import AddNoteModal from "./AddNoteModal";
import ViewShipmentModal from "./ViewShipmentModal";

const ShipmentsList = () => {
    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [selectedShipment, setSelectedShipment] = useState(null);

    const toast = useToast();

    useEffect(() => {
        fetchShipments();
    }, []);

    const fetchShipments = async () => {
        setLoading(true);
        try {
            const response = await getShipments();

            if (response.success) {
                setShipments(response.data);
            } else {
                setShipments([]);
            }
        } catch (error) {
            toast({ title: "Error fetching shipments", status: "error" });
        }
        setLoading(false);
    };

    const handleMarkReturned = async (shipmentId, itemId) => {
        try {
            const response = await markItemReturned(shipmentId, itemId);
            if (response.success) {
                toast({ title: "Item marked as returned", status: "success" });
                fetchShipments();
            } else {
                toast({ title: "Failed to update item", status: "error" });
            }
        } catch (error) {
            toast({ title: "Error updating item", status: "error" });
        }
    };

    if (!hasPermission('viewShipments')) {
        return <AccessDenied msg={"You don't have permission to view this page"} />;
    }

    return (
        <Box p={5}>
            <TitleHeader title="Client Shipments" buttonText={"Add Shipment"} onClick={() => setIsModalOpen(true)} />

            {loading ? <Spinner size="xl" /> : (
                shipments.length > 0 ? (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Client</Th>
                                <Th>Address</Th>
                                <Th>Items</Th>
                                <Th>Shipped At</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipments.map((shipment) => (
                                <Tr key={shipment._id} >
                                    <Td>{shipment.clientName}</Td>
                                    <Td>{shipment.clientAddress || "N/A"}</Td>
                                    <Td>
                                        {shipment.items.map((item, index) => (
                                            <Box key={index} mb={1}>
                                                {item.equipmentName} - {item.serialNumber}
                                                <Badge colorScheme={item.returned ? "green" : "red"} ml={2}>
                                                    {item.returned ? "Returned" : "Not Returned"}
                                                </Badge>
                                            </Box>
                                        ))}
                                    </Td>
                                    <Td>{new Date(shipment.shippedAt).toLocaleString()}</Td>
                                    <Td>
                                        <Button size="sm" onClick={() => { setSelectedShipment(shipment); setIsNoteModalOpen(true); }}>
                                            Add Note
                                        </Button>

                                        <Button size="sm" colorScheme={'green'} ml={4} onClick={() => { setSelectedShipment(shipment); }}>
                                            View
                                        </Button>


                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Box mt={10} textAlign="center">
                        <AccessDenied msg="No shipments yet" />
                    </Box>
                )
            )}



            <ViewShipmentModal
                isOpen={!!selectedShipment}
                onClose={() => setSelectedShipment(null)}
                shipment={selectedShipment}
                refreshShipments={fetchShipments}
            />

            <AddShipmentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <AddNoteModal isOpen={isNoteModalOpen} shipment={selectedShipment} onClose={() => setIsNoteModalOpen(false)} />
        </Box>
    );
};

export default ShipmentsList;
