import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Center,
    useToast,
    Alert,
    AlertDescription,
    Input,
    Text
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { cancelPickup, getAllPickups } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import { hasPermission } from '../../Utils';
import PopoverId from '../../components/PopoverId';
import { FaTruck } from 'react-icons/fa';
import CreateTempPickupModal from './CreateTempPickupModal';
import CreatePickupModal from './CreatePickupModal';


const RequestPickup = () => {
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();

    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [isCreateTempOpen, setIsCreateTempOpen] = useState(false);


    const toast = useToast()
    const [allPickups, setAllPickups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [selectedPickup, setSelectedPickup] = useState(null);
    const itemsPerPage = 25;

    const [cancellationReason, setCancellationReason] = useState('');
    const [modalError, setModalError] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        getPickups();
    }, []);

    const getPickups = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const response = await getAllPickups(page, itemsPerPage, "TEMP");
            if (response.success) {
                const pickups = response.data.pickups;
                setAllPickups(pickups);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load routes');
        }
        setLoading(false);
    };


    const handleDeleteRoute = async () => {
        setLoadingProcess(true);
        try {
            if (!cancellationReason) {
                setModalError("You need to type cancellation reason before canceling order")
                setLoadingProcess(false);
                return;
            }

            const response = await cancelPickup(selectedPickup._id, cancellationReason);
            if (response.success) {
                setAllPickups(allPickups.filter(pickup => pickup._id !== selectedPickup._id));
                toast({
                    title: "Canceled Successful",
                    description: `The pickup has been canceled Completely`,
                    status: "success",
                    duration: 3000, // duration in milliseconds
                    isClosable: true,
                });
                setCancellationReason("");
                setModalError("")
                onCloseDeleteModal();
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Error deleting pickup');
        }
        setLoadingProcess(false);
    };

    const closeModal = async () => {
        setIsCreateTempOpen(false)
        setIsCreateOpen(false)
        getPickups()
    }


    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title={`Request Pickups`}
                    buttonText={hasPermission('createPickup') ? "Request Pickup" : null}
                    onClick={hasPermission('createPickup') ? () => setIsCreateTempOpen(true) : null}
                />

                {loading ?
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                    :
                    error ?
                        <AccessDenied icon="info" msg={error} />
                        :
                        <>
                            {allPickups.length > 0 ? (
                                <>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>#ID</Th>
                                                <Th>Pickup</Th>
                                                <Th>Note</Th>
                                                <Th textAlign="right"> Actions</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {allPickups?.map((pickup, index) => (
                                                <Tr
                                                    color={pickup.type === 'Supplies' ? 'white' : 'gray.900'}
                                                    bg={pickup.type === 'DropOffOnly' ? "pink.100" : pickup.type === 'Supplies' ? 'purple' : 'white'}
                                                    _hover={{ bg: "gray.700", color: "white" }} key={pickup._id}
                                                >
                                                    <Td maxW={1}><PopoverId place='Pickup' index={index} content={pickup._id} /></Td>
                                                    <Td>{pickup?.pickupLocation?.name || "N/A"}</Td>
                                                    <Td>{pickup?.notes[0]?.noteText || "No Notes"}</Td>


                                                    <Td textAlign="right">
                                                        <HStack justifyContent="flex-end" spacing={4}>

                                                            {hasPermission('createPickup') &&

                                                                <Button onClick={() => { setSelectedPickup(pickup); setIsCreateOpen(true); }} size="xs" colorScheme="green">
                                                                    <FaTruck style={{ marginRight: 10 }} /> <Text>Create Pickup</Text>
                                                                </Button>
                                                            }


                                                            {hasPermission('deletePickup') &&

                                                                <Button onClick={() => { setSelectedPickup(pickup); onOpenDeleteModal(); }} size="xs" colorScheme="red">
                                                                    <DeleteIcon mr={2} /> Cancel
                                                                </Button>
                                                            }



                                                        </HStack>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table >
                                </>
                            ) : (
                                <AccessDenied msg="Nothing here yet !" icon='info' />
                            )}
                        </>
                }


                {selectedPickup &&

                    <>
                        <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Confirm Cancellation</ModalHeader>
                                <ModalBody>

                                    {modalError &&
                                        <Alert mb={5} status='error'>
                                            <AlertDescription>{modalError}</AlertDescription>
                                        </Alert>
                                    }


                                    Are you sure you want to cancel this pickup ?
                                    <br /><br />
                                    <strong>{selectedPickup?.pickupLocation?.name || ''}</strong>
                                    <br /><br />
                                    <FormControl mb={3} isRequired>
                                        <FormLabel>Cancellation Reason</FormLabel>
                                        <Input
                                            type="text"
                                            value={cancellationReason}
                                            onChange={(e) => setCancellationReason(e.target.value)}
                                            placeholder="Cancellation Reason"
                                        />
                                    </FormControl>

                                </ModalBody>
                                <ModalFooter>
                                    <Button mr={5} isLoading={loadingProcess} colorScheme="red" onClick={handleDeleteRoute}>Yes</Button>
                                    <Button onClick={onCloseDeleteModal}>No</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        <CreatePickupModal
                            isOpen={isCreateOpen}
                            onClose={() => closeModal()}
                            selectedPickup={selectedPickup}
                        />
                    </>

                }

                <CreateTempPickupModal
                    isOpen={isCreateTempOpen}
                    onClose={() => closeModal()}
                />




            </VStack >
        </Box >
    );
};

export default RequestPickup;
