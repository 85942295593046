import React, { useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    Button, Textarea, useToast
} from "@chakra-ui/react";
import { addShipmentNote } from "../../api";

const AddNoteModal = ({ isOpen, onClose, shipment }) => {
    const [noteText, setNoteText] = useState("");
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const handleAddNote = async () => {
        if (!noteText.trim()) return toast({ title: "Note cannot be empty", status: "warning" });

        setLoading(true);
        try {
            const response = await addShipmentNote(shipment._id, { text: noteText, createdBy: "AdminUser" });

            if (response.success) {
                toast({ title: "Note added successfully", status: "success" });
                setNoteText("");
                onClose();
            } else {
                toast({ title: "Failed to add note", status: "error" });
            }
        } catch (error) {
            toast({ title: "Error adding note", status: "error" });
        }
        setLoading(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Note for {shipment?.clientName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Textarea placeholder="Enter note here..." value={noteText} onChange={(e) => setNoteText(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" isLoading={loading} onClick={handleAddNote}>
                        Add Note
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddNoteModal;
