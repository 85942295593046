import React, { useEffect, useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    Stat,
    StatLabel,
    StatNumber,
    SimpleGrid,
    Spinner,
    Alert,
    AlertIcon,
    Text,
    Icon,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Card,
    CardHeader,
    CardBody
} from '@chakra-ui/react';
import { FaTruck, FaCheckCircle, FaCalendarAlt, FaBuilding, FaBoxOpen } from 'react-icons/fa';
import { fetchOrdersWidgets } from '../api';
import { hasPermission } from '../Utils';
import ClientsOrdersWidgets from './Dashboard/ClientsOrdersWidgets';

const Dashboard = () => {
    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={8} align="stretch">
                <Heading>Dashboard</Heading>

                {hasPermission("clientOrderWidget") && (
                    <ClientsOrdersWidgets />
                )}
            </VStack>
        </Box>
    );
};

export default Dashboard;
