import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Heading, VStack, HStack, Button, Table, Thead, Tbody, Tr, Th, Td, Input, Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Text,
    Divider,
    Progress
} from '@chakra-ui/react';
import { uploadDocuments, softDeleteDocument, downloadDocument } from '../../api';  // Import API functions
import { getAllDocuments } from '../../api';
import { alert } from '../../Utils';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';
import { CheckIcon, CloseIcon, DownloadIcon, ViewIcon } from '@chakra-ui/icons';
import PopoverId from '../../components/PopoverId';

const DocumentsPage = () => {
    const [documents, setDocuments] = useState([]);  // Store documents
    const [loading, setLoading] = useState(true);  // Track loading state
    const [selectedFiles, setSelectedFiles] = useState([]);  // Store selected files for upload
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');  // Search term for filtering documents
    const [currentPage, setCurrentPage] = useState(1);  // Track the current page
    const itemsPerPage = 10;  // Set the number of items per page
    const [uploadProgress, setUploadProgress] = useState([]);  // Track upload progress for each file
    const [uploading, setUploading] = useState(false)
    const { isOpen: isUploadModalOpen, onOpen: onOpenUploadModal, onClose: onCloseUploadModal } = useDisclosure();  // Modal for file upload
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();  // Modal for delete confirmation
    const [documentToDelete, setDocumentToDelete] = useState(null);  // Track the document to delete

    useEffect(() => {
        fetchAllDocuments();
    }, []);

    // Fetch documents from the server
    const fetchAllDocuments = async () => {
        try {
            const response = await getAllDocuments();
            if (response.success) {
                setDocuments(response.data);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    };


    const handleFileChange = useCallback((e) => {

        const newSelectedFiles = Array.from(e.target.files);

        // Merge with previously selected files, ensuring no duplicates
        setSelectedFiles((prevFiles) => {
            const existingFileNames = prevFiles.map(file => file.name);
            const filteredNewFiles = newSelectedFiles.filter(file => !existingFileNames.includes(file.name));

            return [...prevFiles, ...filteredNewFiles]; // Efficiently append new files
        });
    }, []);



    const handleFileUpload = async () => {
        if (!selectedFiles || selectedFiles.length === 0) return;

        setUploading(true); // Indicate the upload process has started
        const uploadProgress = [...selectedFiles].map(() => 0); // Initialize progress for each file
        setUploadProgress(uploadProgress);

        let allFilesUploadedSuccessfully = true; // Track overall success
        let uploadCount = 0; // To track how many files have finished uploading

        for (let i = 0; i < selectedFiles.length; i++) {
            const formData = new FormData();
            formData.append('documents', selectedFiles[i]);

            // Use XMLHttpRequest to track upload progress
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress((prevProgress) => {
                        const updatedProgress = [...prevProgress];
                        updatedProgress[i] = progress;
                        return updatedProgress;
                    });
                }
            });

            xhr.open('POST', `${process.env.REACT_APP_SERVER_URL}/documents/upload`, true);
            xhr.withCredentials = true;

            xhr.onload = () => {
                if (xhr.status === 201) {
                    setUploadProgress((prevProgress) => {
                        const updatedProgress = [...prevProgress];
                        updatedProgress[i] = 100; // Mark as fully uploaded
                        return updatedProgress;
                    });
                } else {
                    allFilesUploadedSuccessfully = false;
                    alert("Oops!", 'error', `Error uploading file ${selectedFiles[i].name}`, 'Okay!');
                }

                uploadCount += 1;

                // Check if all files have finished uploading
                if (uploadCount === selectedFiles.length) {
                    setUploading(false); // Stop the loading state

                    if (allFilesUploadedSuccessfully) {
                        alert("Success", 'success', 'All files uploaded successfully', 'Okay!');
                    }

                    // Close the modal and reset state
                    onCloseUploadModal(); // Close the modal after all files are uploaded
                    setSelectedFiles([]); // Clear selected files
                    setUploadProgress([]); // Reset upload progress

                    // Fetch documents only after all uploads are done
                    fetchAllDocuments();
                }
            };

            xhr.onerror = () => {
                allFilesUploadedSuccessfully = false;
                alert("Oops!", 'error', `Upload failed for file ${selectedFiles[i].name}`, 'Okay!');

                uploadCount += 1;

                // Check if all files have finished uploading, even if there was an error
                if (uploadCount === selectedFiles.length) {
                    setUploading(false); // Stop the loading state
                    onCloseUploadModal(); // Close the modal after all files are uploaded
                    setSelectedFiles([]); // Clear selected files
                    setUploadProgress([]); // Reset upload progress

                    // Fetch documents after all uploads are done
                    fetchAllDocuments();
                }
            };

            xhr.send(formData); // Send each file one by one
        }
    };







    // Handle document deletion (soft-delete)
    const handleDelete = async () => {
        try {
            const response = await softDeleteDocument(documentToDelete._id);
            if (response.success) {
                alert("Deleted!", 'success', 'File deleted successfully', 'Okay!');
                fetchAllDocuments();
            } else {
                alert("Oops!", 'error', response.error, 'Okay!');
            }
        } catch (error) {
            alert("Oops!", 'error', 'Error deleting file, Try again later', 'Okay!');
        } finally {
            onCloseDeleteModal();  // Close confirmation modal
        }
    };

    // Handle document download
    const handleDownload = async (id) => {
        const download = await downloadDocument(id);
        if (!download.success) {
            alert("Oops!", 'error', download.error, 'Okay!');
        }
    };

    // Search and filter documents
    const filteredDocuments = documents.filter(doc =>
        doc.filename.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentDocuments = filteredDocuments.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredDocuments.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Files and Docs" buttonText={"Upload Files"} onClick={onOpenUploadModal} />

                {/* Search Input */}
                <Input
                    placeholder="Search documents"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    mb={4}
                />

                {/* Documents Table */}
                {error ? (
                    <AccessDenied msg={error} />
                ) : (
                    <>
                        {currentDocuments.length === 0 ?
                            <AccessDenied msg={"Nothing here yet"} icon='info' />
                            :
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th w={1}>#</Th>
                                        <Th w={"45%"}>Filename</Th>
                                        <Th>Uploaded By</Th>
                                        <Th>Uploaded At</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {currentDocuments.map((doc, index) => (
                                        <Tr key={doc._id} _hover={{ bg: "gray.100" }}>
                                            <Td><PopoverId place='Documents Management' index={index} content={doc._id} /></Td>
                                            <Td>{doc.filename}</Td>
                                            <Td>{doc.uploadedBy.firstName + " " + doc.uploadedBy.lastName}</Td>
                                            <Td>{new Date(doc.createdAt).toLocaleString()}</Td>
                                            <Td>
                                                <HStack spacing={4}>
                                                    <Button size="sm" colorScheme="green" onClick={() => handleDownload(doc._id)}>
                                                        <ViewIcon mr={2} /> View
                                                    </Button>
                                                    <Button size="sm" colorScheme="blue" onClick={() => handleDownload(doc._id)}>
                                                        <DownloadIcon mr={2} /> Download
                                                    </Button>
                                                    <Button size="sm" colorScheme="red" onClick={() => {
                                                        setDocumentToDelete(doc); onOpenDeleteModal();  // Open delete confirmation modal
                                                    }}>
                                                        <CloseIcon mr={2} /> Delete
                                                    </Button>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        }

                        {/* Pagination Controls */}
                        <HStack spacing={4} mt={4} justify="center">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    colorScheme={currentPage === index + 1 ? 'teal' : 'gray'}
                                >
                                    {index + 1}
                                </Button>
                            ))}
                        </HStack>
                    </>
                )}

                {/* Confirmation Modal for Delete */}
                <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete Confirmation</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete this document?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button onClick={onCloseDeleteModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Upload Modal */}
                <Modal size={"xls"} scrollBehavior='inside' isOpen={isUploadModalOpen} onClose={onCloseUploadModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Upload Files</ModalHeader>
                        <ModalBody>
                            <Center>
                                <Box mb={4}>
                                    <Button as="label" colorScheme="blue" cursor="pointer">
                                        Select Files To Upload
                                        <Input
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                            display="none"  // Hide the default input field
                                        />
                                    </Button>
                                    <Text mt={2} fontSize="sm" color="gray.500">
                                        You can select up to 10 files.
                                    </Text>
                                </Box>
                            </Center>

                            <Divider />


                            {/* Display selected files */}
                            {selectedFiles.length > 0 && (
                                <Box mt={4}>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>File Name</Th>
                                                <Th>Size</Th>
                                                <Th>Progress</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {selectedFiles.map((file, index) => (
                                                <Tr key={index}>
                                                    <Td>{file.name}</Td>
                                                    <Td>{(file?.size / (1024 * 1024)).toFixed(2) || "N/A"} MB</Td>
                                                    <Td>

                                                        <Box position="relative" width="100%">
                                                            {uploadProgress[index] !== 100 &&
                                                                <Progress value={uploadProgress[index]} size="sm" />
                                                            }
                                                            {uploadProgress[index] === 100 && (
                                                                <Box position="absolute" top="0" right="0">
                                                                    <CheckIcon boxSize={6} color="green.500" />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Td>
                                                    <Td>
                                                        <Button size="sm" colorScheme="red" onClick={() => {
                                                            const updatedFiles = [...selectedFiles];
                                                            updatedFiles.splice(index, 1);
                                                            setSelectedFiles(updatedFiles);
                                                            const updatedProgress = [...uploadProgress];
                                                            updatedProgress.splice(index, 1);
                                                            setUploadProgress(updatedProgress);
                                                        }}>Remove</Button>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Box>
                            )}

                        </ModalBody>
                        <ModalFooter>
                            <Button isLoading={uploading} colorScheme="blue" size={"sm"} mr={3} onClick={handleFileUpload}>
                                Upload
                            </Button>
                            <Button size={"sm"} isLoading={uploading} onClick={onCloseUploadModal}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </VStack>
        </Box>
    );
};

export default DocumentsPage;
