import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderById } from '../../api';
import {
    Box,
    Heading,
    Center,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    VStack,
    HStack,
    Spinner
} from '@chakra-ui/react';

const PrintOrderPage = () => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadOrder = async () => {
            const result = await fetchOrderById(id);
            if (result.success) {
                setOrder(result.data);
            }
            setLoading(false);
        };
        loadOrder();
    }, [id]);

    useEffect(() => {
        // Once order is loaded, trigger print
        if (order && !loading) {
            window.print();
        }
    }, [order, loading]);

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Spinner />
            </Center>
        );
    }

    if (!order) {
        return (
            <Center mt={"20%"}>
                <Text>Could not load order details.</Text>
            </Center>
        );
    }

    return (
        <Box p={8} className="print-container">
            <Heading size="lg" mb={8} textAlign="center">
                Order {order.business?.name || order.clientName}
            </Heading>

            <VStack align="start" mb={10}>
                <HStack>
                    <Text fontWeight="bold">Client Name:</Text>
                    <Text>{order.business?.name || order.clientName}</Text>
                </HStack>
                <HStack>
                    <Text fontWeight="bold">Date:</Text>
                    <Text>{new Date(order.createdAt).toLocaleString()}</Text>
                </HStack>
                {order.note && (
                    <Box>
                        <Text fontWeight="bold">Order Note:</Text>
                        <Text>{order.note}</Text>
                    </Box>
                )}
            </VStack>

            <Heading size="md" mb={4}>Requested Items</Heading>
            <Table variant="striped" size="sm">
                <Thead>
                    <Tr>
                        <Th>Item Name</Th>
                        <Th>Size</Th>
                        <Th>Requested Qty</Th>
                        <Th>Sent Qty</Th>
                        <Th>Note</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {order.requestedItems.map((item, index) => (
                        <Tr key={item.item._id}>
                            <Td>{item.item.name}</Td>
                            <Td>{item.item.size}</Td>
                            <Td>{item.quantityRequested}</Td>
                            <Td>{item.quantitySent}</Td>
                            <Td>{item.note || ''}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <Center mt={10}>
                <Text fontSize='sm' color='gray.500'>End of Order</Text>
            </Center>
        </Box>
    );
};

export default PrintOrderPage;
