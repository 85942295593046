import React, { useState, useEffect } from "react";
import {
    Box, Table, Thead, Tbody, Tr, Th, Td, Button, Input, Select, Spinner, useToast,
    Badge,
} from "@chakra-ui/react";
import { getMessages } from "../../api";
import AccessDenied from "../../components/AccessDenied";
import { hasPermission, trimText } from "../../Utils";
import SendMessageModal from "./SendMessageModal";
import TitleHeader from '../../components/TitleHeader';


const MessagesList = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const toast = useToast();

    useEffect(() => {
        fetchMessages();
    }, [page]);

    const fetchMessages = async () => {
        setLoading(true);
        try {
            const response = await getMessages(1, 500);

            if (response.success) {
                setMessages(response.data.messages);
            } else {
                setMessages([])
            }


        } catch (error) {
            toast({ title: "Error fetching messages", status: "error" });
        }
        setLoading(false);
    };

    if (!hasPermission('viewMessages')) {
        return (
            <AccessDenied msg={"You don't have permission to view this page"} />
        )
    }

    return (
        <Box p={5}>

            <TitleHeader title="Couriers Messaging" buttonText={"Send Message"} onClick={() => setIsModalOpen(true)} />


            {loading ? <Spinner size="xl" /> : (

                messages.length > 0 ?
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Message</Th>
                                <Th>Courier</Th>
                                <Th>Sender</Th>
                                <Th>Status</Th>
                                <Th>Created At</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {messages.map((msg) => (
                                <Tr key={msg.id}>
                                    <Td>{trimText(msg.message, 20)}</Td>
                                    <Td>{msg.toAll ? "To All" : msg.courierId?.name || "N/A"}</Td>
                                    <Td>{msg.sender?.firstName || "N/A"}</Td>
                                    <Td>
                                        <Badge
                                            colorScheme={
                                                msg.toAll ? 'yellow' :
                                                    msg.status == 'unread'
                                                        ? "pink"
                                                        : 'green'
                                            }
                                        >
                                            {msg.toAll ? "N/A" : msg.status}
                                        </Badge>
                                    </Td>
                                    <Td>
                                        {new Date(msg.createdAt).toLocaleString()}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    :
                    <Box mt={10} justifyContent={'center'} alignContent={'ce'}>
                        <AccessDenied msg="No messages yet" />
                    </Box>
            )}
            {/* 
            {messages.length > 0 &&
                <Button mt={4} onClick={() => setPage(page + 1)}>Load More</Button>
            } */}

            <SendMessageModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />


        </Box>
    );
};

export default MessagesList;
