import React, { useState, useEffect } from 'react';
import { fetchSupplies, createSupply, updateSupply, deleteSupply, fetchCategories } from '../../api';
import { Box, Button, Table, Tbody, Td, Th, Thead, Tr, VStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, Text, Alert, AlertIcon, AlertDescription, Center, HStack, Select, Heading, Badge, Tooltip, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from '@chakra-ui/react';
import { CloseIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';
import PopoverId from '../../components/PopoverId';
import { hasPermission } from '../../Utils';

const SuppliesManagement = () => {
    const [supplies, setSupplies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedSupply, setSelectedSupply] = useState(null);
    const [newSupply, setNewSupply] = useState({ name: '', category: '', active: true, sku: '', unitCost: '', reorderLevel: '' });
    const [errorModal, setErrorModal] = useState(null);
    const [errorSupplies, setErrorSupplies] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredSupplies, setFilteredSupplies] = useState([])
    const itemsPerPage = 20;


    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    // Fetch supplies and categories when the component mounts
    useEffect(() => {
        fetchSuppliesData();
        fetchCategoriesData();
    }, []);

    const fetchSuppliesData = async () => {
        setLoading(true);
        const result = await fetchSupplies(false);
        if (result.success) {
            setSupplies(result.data);
        } else {
            setErrorSupplies(result.error);
        }
        setLoading(false);
    };

    const fetchCategoriesData = async () => {
        const result = await fetchCategories();
        if (result.success) {
            setCategories(result.data);
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle supply creation
    const handleCreateSupply = async () => {
        setErrorModal(null);
        if (!newSupply.name || !newSupply.category) {
            setErrorModal('Supply name and category are required');
            return;
        }
        const result = await createSupply(newSupply);
        if (result.success) {
            setSupplies((prevSupplies) => [...prevSupplies, result.data]);
            onCreateClose();
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle supply update
    const handleUpdateSupply = async () => {
        setErrorModal(null);
        if (!selectedSupply.name || !selectedSupply.category) {
            setErrorModal('Supply name and category are required');
            return;
        }
        const result = await updateSupply(selectedSupply._id, selectedSupply);
        if (result.success) {
            setSupplies((prevSupplies) =>
                prevSupplies.map((supply) =>
                    supply._id === selectedSupply._id ? result.data : supply
                )
            );
            onEditClose();
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle supply deletion (soft delete)
    // Handle deletion (soft delete) of a supply item
    const handleDeleteSupply = async () => {
        const result = await deleteSupply(selectedSupply._id);
        if (result.success) {
            // Update supplies state by filtering out the deleted item
            setSupplies((prevSupplies) =>
                prevSupplies.filter((supply) => supply._id !== selectedSupply._id)
            );
            onDeleteClose();
        } else {
            setErrorModal(result.error);
        }
    };

    const handleClose = (modalCloseFunc) => {
        setErrorModal(null);
        modalCloseFunc(); // Call the specific modal's onClose function
    };

    useEffect(() => {
        handleSearch();
    }, [searchQuery, supplies]);

    const handleSearch = () => {
        const filtered = supplies.filter(supply =>
            (supply.name.toLowerCase()).includes(searchQuery.toLowerCase())
        );
        setFilteredSupplies(filtered);
    };


    // Calculate total pages
    const totalPages = Math.ceil(filteredSupplies.length / itemsPerPage);

    // Paginate supplies
    const paginate = (suppliesList) => {
        const start = (currentPage - 1) * itemsPerPage;
        return suppliesList.slice(start, start + itemsPerPage);
    };

    // Render page numbers
    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <Button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    colorScheme={i === currentPage ? 'blue' : 'gray'} // Highlight the current page
                    mx={1}
                    size="sm"
                >
                    {i}
                </Button>
            );
        }
        return pageNumbers;
    };


    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">

                <TitleHeader title="Supplies Management" buttonText={hasPermission("createSupply") ? "New Supply" : null} onClick={hasPermission("createSupply") ? onCreateOpen : null} />

                {errorSupplies &&
                    <AccessDenied msg={errorSupplies} />
                }

                {!errorSupplies &&
                    <>
                        {supplies.length === 0 ? <AccessDenied msg={"Nothing here yet !"} icon="info" /> :
                            <>
                                <Input
                                    placeholder="Search supplies..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    mb={4}
                                />

                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            {/* <Th w={1}>#</Th> */}
                                            <Th width={"30%"}>Name</Th>
                                            <Th>Size</Th>
                                            <Th>Category</Th>
                                            <Th>SKU</Th>
                                            {hasPermission('viewPricing') && <Th>Coast</Th>}
                                            <Th>Active</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {paginate(filteredSupplies).map((supply, index) => (
                                            <Tr key={supply._id} _hover={{ bg: "gray.100" }}>
                                                {/* <Td><PopoverId place='Supplies Items Management' index={index} content={supply._id} /></Td> */}
                                                <Td>{supply.name}</Td>
                                                <Td>{supply.size || "N/A"}</Td>
                                                <Td>{supply.category?.name || 'N/A'}</Td>
                                                <Td>{supply?.sku || "N/A"}</Td>
                                                {hasPermission('viewPricing') && <Td><Badge colorScheme="purple">{supply?.unitCost || 0}</Badge></Td>}
                                                <Td>{supply.active ? <Badge colorScheme="green">Yes</Badge> : <Badge colorScheme="red">No</Badge>}</Td>
                                                <Td>
                                                    <HStack spacing={4}>
                                                        {hasPermission("updateSupply") &&
                                                            <Button size="sm" colorScheme="green" onClick={() => {
                                                                setSelectedSupply(supply);
                                                                onEditOpen();
                                                            }}>
                                                                <EditIcon mr={2} /> Edit
                                                            </Button>
                                                        }

                                                        {hasPermission("deleteSupply") &&
                                                            <Button size="sm" colorScheme="red" onClick={() => {
                                                                setSelectedSupply(supply);
                                                                onDeleteOpen();
                                                            }}>
                                                                <CloseIcon mr={2} /> Delete
                                                            </Button>
                                                        }
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                                <HStack justify="center" mt={4}>
                                    <Button
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        isDisabled={currentPage === 1}
                                        size="sm"
                                    >
                                        Previous
                                    </Button>

                                    {renderPageNumbers()}

                                    <Button
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        isDisabled={currentPage === totalPages}
                                        size="sm"
                                    >
                                        Next
                                    </Button>
                                </HStack>
                            </>
                        }

                        {/* Create Supply Modal */}
                        <Modal isOpen={isCreateOpen} onClose={() => handleClose(onCreateClose)}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Create Supply</ModalHeader>
                                <ModalCloseButton />
                                {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                                <ModalBody>
                                    <FormControl>
                                        <Text>Supply Name</Text>
                                        <Input value={newSupply.name} onChange={(e) => setNewSupply({ ...newSupply, name: e.target.value })} />
                                    </FormControl>
                                    <FormControl mt={4}>
                                        <Text>Supply Size</Text>
                                        <Input value={newSupply.size} onChange={(e) => setNewSupply({ ...newSupply, size: e.target.value })} />
                                    </FormControl>
                                    <FormControl mt={4}>
                                        <Text>SKU</Text>
                                        <Input value={newSupply.sku} onChange={(e) => setNewSupply({ ...newSupply, sku: e.target.value })} />
                                    </FormControl>
                                    {hasPermission('viewPricing') &&
                                        <FormControl mt={4}>
                                            <Text>Unit Cost</Text>
                                            <Input type="number" value={newSupply.unitCost} onChange={(e) => setNewSupply({ ...newSupply, unitCost: e.target.value })} />
                                        </FormControl>
                                    }
                                    <FormControl mt={4}>
                                        <Text>Reorder Level</Text>
                                        <Input type="number" value={newSupply.reorderLevel} onChange={(e) => setNewSupply({ ...newSupply, reorderLevel: e.target.value })} />
                                    </FormControl>
                                    <FormControl mt={4}>
                                        <Text>Category</Text>
                                        <Select placeholder="Select category" value={newSupply.category} onChange={(e) => setNewSupply({ ...newSupply, category: e.target.value })}>
                                            {categories.map(category => (
                                                <option key={category._id} value={category._id}>{category.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={handleCreateSupply}>Create</Button>
                                    <Button ml={3} onClick={() => handleClose(onCreateClose)}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        {/* Edit Supply Modal */}
                        {selectedSupply &&
                            <>
                                <Modal isOpen={isEditOpen} onClose={() => handleClose(onEditClose)}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Edit Supply</ModalHeader>
                                        <ModalCloseButton />
                                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                                        <ModalBody>
                                            <FormControl>
                                                <Text>Supply Name</Text>
                                                <Input value={selectedSupply?.name} onChange={(e) => setSelectedSupply({ ...selectedSupply, name: e.target.value })} />
                                            </FormControl>
                                            <FormControl mt={4}>
                                                <Text>Supply Size</Text>
                                                <Input value={selectedSupply?.size} onChange={(e) => setSelectedSupply({ ...selectedSupply, size: e.target.value })} />
                                            </FormControl>

                                            <FormControl mt={4}>
                                                <Text>SKU</Text>
                                                <Input value={selectedSupply?.sku} onChange={(e) => setSelectedSupply({ ...selectedSupply, sku: e.target.value })} />
                                            </FormControl>
                                            {hasPermission('viewPricing') &&
                                                <FormControl mt={4}>
                                                    <Text>Unit Cost</Text>
                                                    <Input type="number" value={selectedSupply?.unitCost} onChange={(e) => setSelectedSupply({ ...selectedSupply, unitCost: e.target.value })} />
                                                </FormControl>
                                            }
                                            <FormControl mt={4}>
                                                <Text>Reorder Level</Text>
                                                <Input type="number" value={selectedSupply?.reorderLevel} onChange={(e) => setSelectedSupply({ ...selectedSupply, reorderLevel: e.target.value })} />
                                            </FormControl>

                                            <FormControl mt={4}>
                                                <Text>Category</Text>
                                                <Select value={selectedSupply?.category} onChange={(e) => setSelectedSupply({ ...selectedSupply, category: e.target.value })}>
                                                    {categories.map(category => (
                                                        <option key={category._id} value={category._id}>{category.name}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl mt={4}>
                                                <Text>Active</Text>
                                                <Select value={selectedSupply?.active} onChange={(e) => setSelectedSupply({ ...selectedSupply, active: e.target.value })}>
                                                    <option key={true} value={true}>Yes</option>
                                                    <option key={false} value={false}>No</option>

                                                </Select>
                                            </FormControl>

                                        </ModalBody>
                                        <ModalFooter>
                                            <Button colorScheme="green" onClick={handleUpdateSupply}>Save</Button>
                                            <Button ml={3} onClick={() => handleClose(onEditClose)}>Cancel</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>

                                <Modal isOpen={isDeleteOpen} onClose={() => handleClose(onDeleteClose)}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Delete Supply</ModalHeader>
                                        <ModalCloseButton />
                                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                                        <ModalBody>Are you sure you want to delete the supply "{selectedSupply?.name}"?</ModalBody>
                                        <ModalFooter>
                                            <Button colorScheme="red" onClick={handleDeleteSupply}>Yes, Delete</Button>
                                            <Button ml={3} onClick={() => handleClose(onDeleteClose)}>No</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </>
                        }
                    </>
                }



            </VStack>
        </Box>
    );
};

export default SuppliesManagement;
