// Set the base URL for your backend
// const API_URL = 'https://thelabsys.com/api'; // Adjust if necessary
const API_URL = process.env.REACT_APP_SERVER_URL; // Adjust if necessary

// Helper function to handle fetch responses
const handleResponse = async (response) => {

    const result = await response.json();
    if (!result.success) {
        const msgs = ['Access denied. No token provided.', 'Access denied.', 'User not found', 'Invalid token.'];
        const tokenMsgs = ['NO_TOKEN', 'INVALID_TOKEN', 'NO_USER_TOKEN'];
        const { message } = result

        if (tokenMsgs.includes(message)) {
            // If the token is invalid, expired, or missing, redirect to the login page
            window.location.href = "/login"
        }

        return { success: false, error: (message || 'Something went wrong'), hideButton: msgs.includes(message) ? false : true };
    }

    return { success: true, data: result.data };
};


///////////////////////////////////////
///////////////Businesses//////////////
///////////////////////////////////////

// Fetch all businesses
export const fetchBusinesses = async () => {
    try {
        const response = await fetch(`${API_URL}/businesses`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching businesses', error);
        throw error;
    }
};

// Create a new business
export const createBusiness = async (businessData) => {
    try {
        const response = await fetch(`${API_URL}/businesses`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(businessData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating business:', error);
        throw error;
    }
};

// Update CS fields for a business
export const updateBusinessCSFields = async (businessId, csFields) => {
    try {
        const response = await fetch(`${API_URL}/businesses/update-cs/${businessId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(csFields),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating CS fields:', error);
        throw error;
    }
};

// Get business details by ID
export const getBusinessDetails = async (id) => {
    try {
        const response = await fetch(`${API_URL}/businesses/getb/${id}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching business details:', error);
        throw error;
    }
};

// Update a business
export const updateBusiness = async (id, data) => {
    try {
        const response = await fetch(`${API_URL}/businesses/update-business/${id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating business:', error);
        throw error;
    }
};

// Fetch business by token
export const fetchBusinessByToken = async (token) => {
    try {
        const response = await fetch(`${API_URL}/businesses/complete-business/${token}`);
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching business by token:', error);
        throw error;
    }
};

// Submit business form
export const submitBusinessForm = async (token, data) => {
    try {
        const response = await fetch(`${API_URL}/businesses/complete-business/${token}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error submitting business form:', error);
        throw error;
    }
};

// Generate a link for business
export const generateLinkForBusiness = async (businessId) => {
    try {
        const response = await fetch(`${API_URL}/businesses/generate-link/${businessId}`, {
            method: 'POST',
            credentials: 'include',
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error generating link:', error);
        throw error;
    }
};

// Soft delete a business
export const deleteBusiness = async (businessId) => {
    try {
        const response = await fetch(`${API_URL}/businesses/${businessId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error deleting business:', error);
        throw error;
    }
};


///////////////////////////////////////
///////////////Sales Reps//////////////
///////////////////////////////////////

// Add a new sales rep
export const addNewSalesRep = async (salesRepData) => {
    try {
        const response = await fetch(`${API_URL}/sales-reps`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(salesRepData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating sales rep:', error);
        throw error;
    }
};

// Update a sales rep
export const updateSalesRep = async (id, updatedSalesRep) => {
    try {
        const response = await fetch(`${API_URL}/sales-reps/${id}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSalesRep),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating sales rep:', error);
        throw error;
    }
};

// Fetch all sales reps
export const fetchSalesReps = async () => {
    try {
        const response = await fetch(`${API_URL}/sales-reps`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching sales reps:', error);
        throw error;
    }
};

// Delete a sales rep by ID
export const deleteSalesRepById = async (id) => {
    try {
        const response = await fetch(`${API_URL}/sales-reps/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting sales rep:', error);
        throw error;
    }
};

// Clients by the Sales rep
export const fetchSalesRepClients = async (salesRepId) => {
    try {
        const response = await fetch(`${API_URL}/sales-reps/${salesRepId}/clients`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching sales rep clients:', error);
        throw error;
    }
};




///////////////////////////////////////////
///////////////Authentication/////////////
/////////////////////////////////////////

// Login User
export const loginUser = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}/auth/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        return handleResponse(response);  // Handle the response, just like other functions
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};

// Signup a new user
export const signupUser = async (userData) => {
    try {
        const response = await fetch(`${API_URL}/auth/signup`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        return handleResponse(response);  // Handle the response
    } catch (error) {
        console.error('Error during signup:', error);
        throw error;
    }
};

// Logout
export const logoutUser = async () => {
    try {
        const response = await fetch(`${API_URL}/auth/logout`, {
            method: 'POST',
            credentials: 'include',
            credentials: 'include',  // Ensure cookies are included in the request
        });

        if (response.ok) {
            localStorage.removeItem('isAuthenticated');
        }
        return response

    } catch (error) {
        console.error('Error logging out:', error);
        return { success: false, message: 'Server error during logout' };
    }
};




///////////////////////////////////////////
///////////////Users Accounts/////////////
/////////////////////////////////////////


// Get current user details
export const getUserDetails = async () => {
    try {
        const response = await fetch(`${API_URL}/account/me`, {
            method: 'GET',
            credentials: 'include',  // Ensure cookies (token) are sent with the request
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

// Update user account
export const updateUserAccount = async (userData) => {
    try {
        const response = await fetch(`${API_URL}/account/me`, {
            method: 'PUT',
            credentials: 'include',  // Ensure cookies (token) are sent with the request
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating user account:', error);
        throw error;
    }
};



///////////////////////////////////////////////////
///////////////Roles and Permissions//////////////
/////////////////////////////////////////////////

// Fetch all roles
export const getAllRoles = async () => {
    try {
        const response = await fetch(`${API_URL}/roles`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching roles:', error);
        throw error;
    }
};

// Create a new role
export const createRole = async (roleData) => {
    try {
        const response = await fetch(`${API_URL}/roles`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(roleData),  // Send role data as JSON
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating role:', error);
        throw error;
    }
};

// Update a role
export const updateRole = async (roleId, roleData) => {
    try {
        const response = await fetch(`${API_URL}/roles/${roleId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(roleData),  // Send updated role data as JSON
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

// Delete a role
export const deleteRole = async (roleId) => {
    try {
        const response = await fetch(`${API_URL}/roles/${roleId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};





///////////////////////////////////////////////
///////////////Useres Management//////////////
/////////////////////////////////////////////

// 1. Get All Users
export const getAllUsers = async () => {
    try {
        const response = await fetch(`${API_URL}/users`, {
            method: 'GET',
            credentials: 'include',  // Include credentials for cookies
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

// 2. Edit User
export const updateUser = async (userId, updatedUserData) => {
    try {
        const response = await fetch(`${API_URL}/users/${userId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedUserData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};

// 3. Create User
export const createUser = async (userData) => {
    try {
        const response = await fetch(`${API_URL}/users`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

// 4. Delete User
export const deleteUser = async (userId) => {
    try {
        const response = await fetch(`${API_URL}/users/${userId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};

// 5. View User (Get a specific user by ID)
export const viewUser = async (userId) => {
    try {
        const response = await fetch(`${API_URL}/users/${userId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};




/////////////////////////////////////////
///////////////System Logs//////////////
///////////////////////////////////////

export const fetchLogs = async () => {
    try {
        const response = await fetch(`${API_URL}/logs`, {
            method: 'GET',
            credentials: 'include',  // Include credentials (for cookie-based auth)
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.json();
        if (!result.success) {
            throw new Error(result.message || 'Error fetching logs');
        }
        return { success: true, data: result.data };
    } catch (error) {
        console.error('Error fetching logs:', error);
        return { success: false, error: error.message };
    }
};



/////////////////////////////////////////
///////////////Trash////////////////////
///////////////////////////////////////

export const fetchDeletedUsers = async () => {
    return fetch(`${API_URL}/users?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedBusinesses = async () => {
    return fetch(`${API_URL}/businesses?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedRoles = async () => {
    return fetch(`${API_URL}/roles?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedSalesReps = async () => {
    return fetch(`${API_URL}/sales-reps?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedDocuments = async () => {
    return fetch(`${API_URL}/documents?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedSuppliesCategory = async () => {
    return fetch(`${API_URL}/supplies-categories?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedSuppliesItems = async () => {
    return fetch(`${API_URL}/supplies-items?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedOrders = async () => {
    return fetch(`${API_URL}/client-orders?deleted=true`, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse);
};

export const fetchDeletedLabLocations = async () => {
    try {
        const response = await fetch(`${API_URL}/labs?deleted=true`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching lab locations:', error);
        throw error;
    }
};

export const fetchDeletedFacilities = async () => {
    try {
        const response = await fetch(`${API_URL}/facilities?page=1&limit=10&deleted=true`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching facilities:', error);
        throw error;
    }
};

export const fetchDeletedPSCLocations = async () => {
    try {
        const response = await fetch(`${API_URL}/pscs?page=1&limit=999&deleted=true`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching facilities:', error);
        throw error;
    }
};

export const restoreItem = async (type, id) => {
    return fetch(`${API_URL}/${type}/restore/${id}`, {
        method: 'PUT',
        credentials: 'include',
    }).then(handleResponse);
};


/////////////////////////////////////////
///////////////Documents////////////////
///////////////////////////////////////
// Upload multiple files
export const getAllDocuments = async (formData) => {
    return fetch(`${API_URL}/documents`, {
        method: 'GET',
        credentials: 'include',  // Include credentials (if using cookies for authentication)
    }).then(handleResponse);
};

// Upload multiple files
export const uploadDocuments = async (formData) => {
    return fetch(`${API_URL}/documents/upload`, {
        method: 'POST',
        body: formData,  // Use formData to handle file uploads
        credentials: 'include',  // Include credentials (if using cookies for authentication)
    }).then(handleResponse);
};

// Download a document by ID
export const downloadDocument = async (id) => {
    try {
        const response = await fetch(`${API_URL}/documents/download/${id}`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            if (response.status == 403) {
                return { success: false, error: "Access Denied.", hideButton: false };
            } else {
                return { success: false, error: "Something went wrong, try again later.", hideButton: false };
            }
        }

        // Convert response to blob
        const blob = await response.blob();

        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Extract the filename from Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'downloaded_file'; // Default filename

        if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition
                .split('filename=')[1]
                .replace(/['"]/g, ''); // Remove quotes around filename if any
        }

        // Create a temporary link element to trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;  // Set the filename for the download
        document.body.appendChild(a);
        a.click();  // Trigger the download
        a.remove();  // Clean up the element after download

        // Clean up the object URL after download is triggered
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading document:', error);
    }
};

// Soft delete a document
export const softDeleteDocument = async (id) => {
    return fetch(`${API_URL}/documents/${id}`, {
        method: 'DELETE',  // Soft delete (updates the deletedAt field)
        credentials: 'include',
    }).then(handleResponse);
};



///////////////////////////////////////
///////////////Categories//////////////
///////////////////////////////////////

// Fetch all categories (with optional query to include deleted)
export const fetchCategories = async () => {
    try {
        const response = await fetch(`${API_URL}/supplies-categories`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};

// Create a new category
export const createCategory = async (categoryData) => {
    try {
        const response = await fetch(`${API_URL}/supplies-categories`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating category:', error);
        throw error;
    }
};

// Update an existing category
export const updateCategory = async (categoryId, categoryData) => {
    try {
        const response = await fetch(`${API_URL}/supplies-categories/${categoryId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating category:', error);
        throw error;
    }
};

// Soft delete a category
export const deleteCategory = async (categoryId) => {
    try {
        const response = await fetch(`${API_URL}/supplies-categories/${categoryId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting category:', error);
        throw error;
    }
};

///////////////////////////////////////
///////////////Supply Items////////////
///////////////////////////////////////

// Fetch all supply items (with optional query to include deleted)
export const fetchSupplies = async (active = true) => {
    try {
        const response = await fetch(`${API_URL}/supplies-items?active=${active}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching supplies:', error);
        throw error;
    }
};

// Create a new supply item
export const createSupply = async (supplyData) => {
    try {
        const response = await fetch(`${API_URL}/supplies-items`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(supplyData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating supply:', error);
        throw error;
    }
};

// Update an existing supply item
export const updateSupply = async (supplyId, supplyData) => {
    console.log(supplyData)

    try {
        const response = await fetch(`${API_URL}/supplies-items/${supplyId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(supplyData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating supply:', error);
        throw error;
    }
};

// Soft delete a supply item
export const deleteSupply = async (supplyId) => {
    try {
        const response = await fetch(`${API_URL}/supplies-items/${supplyId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting supply:', error);
        throw error;
    }
};

// Fetch all supply items (with optional query to include deleted)
export const fetchZipCode = async (zip) => {
    try {
        const response = await fetch(`${API_URL}/zip/${zip}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching zipcode:', error);
        throw error;
    }
};

export const fetchSuppliesBySearch = async (searchQuery) => {
    try {
        const response = await fetch(`${API_URL}/supplies-items/supplies-search?search=${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        if (data.success) {
            return data.supplies.map(suply => ({
                value: suply._id,
                label: suply.name,
            }));
        } else {
            throw new Error('Error fetching facilities');
        }

    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

/////////////////////////////////////////
///////////////Client Orders////////////
///////////////////////////////////////

// Create New Order
export const createOrder = async (orderDetails, internal = false) => {
    try {

        const createUrl = internal ? `${API_URL}/client-orders/submit-internal-order` : `${API_URL}/client-orders/submit-order`;


        const response = await fetch(createUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating supply:', error);
        throw error;
    }
};

// Fetch all client orders with optional query for deleted or finished status and pagination
export const fetchAllOrders = async (page = 1, limit = 10, finished = false, deleted = false) => {
    try {
        const response = await fetch(`${API_URL}/client-orders?finished=${finished}&deleted=${deleted}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};

// Update an existing order
export const updateOrder = async (orderId, updatedData) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/${orderId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating order:', error);
        throw error;
    }
};

// Get order by ID
export const fetchOrderById = async (orderId) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/${orderId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching order details');
        throw error;
    }
};

// Update rder Item
export const updateOrderItems = async (orderId, updatedItems) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/${orderId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ requestedItems: updatedItems }),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating order items:', error);
        throw error;
    }
};

// Fetch the last requested date for an item by a specific business
export const fetchLastRequestedDate = async (businessId, itemId, currentOrderId) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/last-requested/${businessId}/${itemId}?currentOrderId=${currentOrderId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching last requested date:', error);
        throw error;
    }
};

// Add an internal note to an existing order
export const addOrderInternalNote = async (orderId, noteText) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/internal-notes/${orderId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ noteText }),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error adding internal note:', error);
        throw error;
    }
};

// Fetch the last 10 orders for a specific business
export const fetchOrdersByBusiness = async (businessId, amount = 0) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/by-business/${businessId}/${amount}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching orders by business:', error);
        throw error;
    }
};


// Soft delete an order
export const softDeleteOrder = async (orderId) => {
    try {
        const response = await fetch(`${API_URL}/client-orders/${orderId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error soft deleting order:', error);
        throw error;
    }
};

export const searchOrders = async ({
    clientId,
    startDate,
    endDate,
    itemId,
    deleted,
    finished = true,
    page = 1,
    limit = 10
}) => {
    try {
        // Construct the request body with only the provided parameters
        const requestBody = {
            clientId,
            startDate,
            endDate,
            itemId,
            deleted,
            finished,
            page,
            limit
        };

        // Make the POST request with the request body
        const response = await fetch(`${API_URL}/client-orders/search`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        return await handleResponse(response);
    } catch (error) {
        console.error('Error searching orders:', error);
        throw error;
    }
};


export const fetchOrdersWidgets = async (limit = 3) => { // ✅ Default to 3 if no limit is provided
    try {
        const response = await fetch(`${API_URL}/client-orders/widgets?limit=${limit}`, { // ✅ Add limit to query
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching orders widgets:', error);
        throw error;
    }
};




/////////////////////////////////////////
///////////////Lab Locations////////////
///////////////////////////////////////

// Fetch all lab locations
export const fetchLabLocations = async () => {
    try {
        const response = await fetch(`${API_URL}/labs`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching lab locations:', error);
        throw error;
    }
};
// Fetch all lab locations
export const fetchLabLocationsOutside = async () => {
    try {
        const response = await fetch(`${API_URL}/labs/view-labs-outside`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching lab locations:', error);
        throw error;
    }
};





// Create a new lab location
export const createLabLocation = async (labLocationDetails) => {
    try {
        const response = await fetch(`${API_URL}/labs`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(labLocationDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating lab location:', error);
        throw error;
    }
};

// Update an existing lab location
export const updateLabLocation = async (labLocationId, updatedDetails) => {
    try {
        const response = await fetch(`${API_URL}/labs/${labLocationId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating lab location:', error);
        throw error;
    }
};


// Soft delete a lab location
export const deleteLabLocation = async (labLocationId) => {
    try {
        const response = await fetch(`${API_URL}/labs/${labLocationId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting lab location:', error);
        throw error;
    }
};


/////////////////////////////////////////
////////////////Facilities//////////////
///////////////////////////////////////


// Get All Facilities (with query for deleted or not)
export const getAllFacilities = async (page = 1, limit = 10, searchParams = {}) => {
    try {
        const query = new URLSearchParams({
            page,
            limit,
            ...searchParams, // Spread search params into the query
        }).toString();

        const response = await fetch(`${API_URL}/facilities?deleted=false&${query}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching facilities:', error);
        throw error;
    }
};


// Get All Facilities (with query for deleted or not)
export const getAllFacilitiesOutside = async (page = 1, limit = 10, searchParams = {}) => {
    try {
        const query = new URLSearchParams({
            page,
            limit,
            ...searchParams, // Spread search params into the query
        }).toString();

        const response = await fetch(`${API_URL}/facilities/view-facilities-outside?deleted=false&${query}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching facilities:', error);
        throw error;
    }
};




// Create a New Facility
export const createFacility = async (facilityDetails) => {
    try {
        const response = await fetch(`${API_URL}/facilities`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(facilityDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating facility:', error);
        throw error;
    }
};

// Update an Existing Facility
export const updateFacility = async (facilityId, updatedDetails) => {
    try {
        const response = await fetch(`${API_URL}/facilities/${facilityId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating facility:', error);
        throw error;
    }
};

// Soft Delete a Facility
export const deleteFacility = async (facilityId) => {
    try {
        const response = await fetch(`${API_URL}/facilities/${facilityId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting facility:', error);
        throw error;
    }
};

// Fetch Facilities by Lab with Pagination
export const fetchFacilitiesByLab = async (labId, page = 1, limit = 10) => {
    try {
        const response = await fetch(`${API_URL}/facilities/facilities-by-lab/${labId}?page=${page}&limit=${limit}&deleted=false`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching facilities by lab:', error);
        throw error;
    }
};

// Fetch Facilities by Lab with Pagination
export const fetchFacilitiesBySalesRep = async (salesRepId, page = 1, limit = 10) => {
    try {
        const response = await fetch(`${API_URL}/facilities/facilities-by-salesrep/${salesRepId}?page=${page}&limit=${limit}&deleted=false`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching facilities by sales rep:', error);
        throw error;
    }
};

export const fetchFacilitiesBySearch = async (searchQuery) => {
    try {
        const response = await fetch(`${API_URL}/facilities/fsearch?search=${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (data.success) {
            return data.facilities.map(facility => ({
                value: facility._id,
                label: facility.name,
            }));
        } else {
            throw new Error('Error fetching facilities');
        }
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};



/////////////////////////////////////////
////////////////PSC's///////////////////
///////////////////////////////////////

// Fetch all PSC Locations with optional deleted filter and pagination
export const fetchPSCLocations = async (page = 1, limit = 10, showDeleted = false) => {
    try {
        const response = await fetch(`${API_URL}/pscs?page=${page}&limit=${limit}&deleted=${showDeleted}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching PSC Locations:', error);
        throw error;
    }
};

// Create a new PSC Location
export const createPSCLocation = async (locationData) => {
    try {
        const response = await fetch(`${API_URL}/pscs`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(locationData)
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating PSC Location:', error);
        throw error;
    }
};

// Update a PSC Location
export const updatePSCLocation = async (locationId, locationData) => {
    try {
        const response = await fetch(`${API_URL}/pscs/${locationId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(locationData)
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating PSC Location:', error);
        throw error;
    }
};

// Soft delete a PSC Location
export const deletePSCLocation = async (locationId) => {
    try {
        const response = await fetch(`${API_URL}/pscs/${locationId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting PSC Location:', error);
        throw error;
    }
};


/////////////////////////////////////////
////////Insurance Categories////////////
///////////////////////////////////////


// Fetch all Insurance Categories with optional deleted filter
export const fetchInsuranceCategories = async (showDeleted = false) => {
    try {
        const response = await fetch(`${API_URL}/insurance-category?deleted=${showDeleted}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching Insurance Categories:', error);
        throw error;
    }
};

// Create a new Insurance Category
export const createInsuranceCategory = async (categoryData) => {
    try {
        const response = await fetch(`${API_URL}/insurance-category`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryData)
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating Insurance Category:', error);
        throw error;
    }
};

// Update an Insurance Category
export const updateInsuranceCategory = async (categoryId, updatedData) => {
    try {
        const response = await fetch(`${API_URL}/insurance-category/${categoryId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData)
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating Insurance Category:', error);
        throw error;
    }
};

// Delete an Insurance Category (soft-delete)
export const deleteInsuranceCategory = async (categoryId) => {
    try {
        const response = await fetch(`${API_URL}/insurance-category/${categoryId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting Insurance Category:', error);
        throw error;
    }
};


/////////////////////////////////////////
//////////////Insurances////////////////
///////////////////////////////////////

// Fetch all Insurances with Pagination and optional deleted filter
export const fetchInsurances = async (page = 1, limit = 10, showDeleted = false) => {
    try {
        const response = await fetch(`${API_URL}/insurance?deleted=${showDeleted}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching Insurances:', error);
        throw error;
    }
};

// Search Insurances with Pagination
export const searchInsurances = async (searchQuery, page = 1, limit = 10) => {
    try {
        const response = await fetch(`${API_URL}/insurance/search?search=${encodeURIComponent(searchQuery)}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error searching Insurances:', error);
        throw error;
    }
};


// Create a new Insurance
export const createInsurance = async (insuranceData) => {
    try {
        const response = await fetch(`${API_URL}/insurance`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(insuranceData)
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating Insurance:', error);
        throw error;
    }
};

// Update an Insurance
export const updateInsurance = async (insuranceId, updatedData) => {
    try {
        const response = await fetch(`${API_URL}/insurance/${insuranceId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData)
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating Insurance:', error);
        throw error;
    }
};

// Delete an Insurance (soft-delete)
export const deleteInsurance = async (insuranceId) => {
    try {
        const response = await fetch(`${API_URL}/insurance/${insuranceId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting Insurance:', error);
        throw error;
    }
};



/////////////////////////////////////////
///////Tests Prices Categoriers/////////
///////////////////////////////////////

// Fetch all Price Categories
export const fetchPriceCategories = async (showDeleted = false) => {
    try {
        const response = await fetch(`${API_URL}/tests-price-category?deleted=${showDeleted}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching Price Categories:', error);
        throw error;
    }
};

// Create a new Price Category
export const createPriceCategory = async (categoryData) => {
    try {
        const response = await fetch(`${API_URL}/tests-price-category`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating Price Category:', error);
        throw error;
    }
};

// Update a Price Category
export const updatePriceCategory = async (categoryId, categoryData) => {
    try {
        const response = await fetch(`${API_URL}/tests-price-category/${categoryId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating Price Category:', error);
        throw error;
    }
};

// Delete a Price Category
export const deletePriceCategory = async (categoryId) => {
    try {
        const response = await fetch(`${API_URL}/tests-price-category/${categoryId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting Price Category:', error);
        throw error;
    }
};


/////////////////////////////////////////
//////////////Tests Prices//////////////
///////////////////////////////////////


// Fetch all Prices with Pagination
export const fetchPrices = async (page = 1, limit = 10, showDeleted = false) => {
    try {
        const response = await fetch(`${API_URL}/tests-price?deleted=${showDeleted}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching Prices:', error);
        throw error;
    }
};

export const searchTestsPrices = async (searchQuery, page = 1, limit = 10) => {
    try {
        const queryParams = new URLSearchParams({
            page,
            limit,
            ...searchQuery, // Include description, testCode, and cptCode dynamically
        });

        const response = await fetch(`${API_URL}/tests-price/search?${queryParams.toString()}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error searching test prices:', error);
        throw error;
    }
};


// Create a new Price
export const createPrice = async (priceData) => {
    try {
        const response = await fetch(`${API_URL}/tests-price`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(priceData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating Price:', error);
        throw error;
    }
};

// Update a Price
export const updatePrice = async (priceId, priceData) => {
    try {
        const response = await fetch(`${API_URL}/tests-price/${priceId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(priceData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating Price:', error);
        throw error;
    }
};

// Delete a Price
export const deletePrice = async (priceId) => {
    try {
        const response = await fetch(`${API_URL}/tests-price/${priceId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting Price:', error);
        throw error;
    }
};




/////////////////////////////////////////
////////////////Couriers////////////////
///////////////////////////////////////
// Fetch All Couriers with optional query for deleted status and pagination
export const fetchAllCouriers = async (page = 1, limit = 10, deleted = false) => {
    try {
        const response = await fetch(`${API_URL}/co/couriers?deleted=${deleted}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching couriers:', error);
        throw error;
    }
};

// Create a New Courier
export const createCourier = async (courierDetails) => {
    try {
        const response = await fetch(`${API_URL}/co/couriers`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(courierDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating courier:', error);
        throw error;
    }
};

// Update an Existing Courier
export const updateCourier = async (courierId, courierDetails) => {
    try {
        const response = await fetch(`${API_URL}/co/couriers/${courierId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(courierDetails),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating courier:', error);
        throw error;
    }
};

// Delete a Courier (Soft Delete)
export const deleteCourier = async (courierId) => {
    try {
        const response = await fetch(`${API_URL}/co/couriers/${courierId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting courier:', error);
        throw error;
    }
};




/////////////////////////////////////////
///////////Couriers Schedule////////////
///////////////////////////////////////

export const fetchCourierSchedule = async (courierId) => {
    try {
        const response = await fetch(`${API_URL}/co/schedule/${courierId}`, {
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in fetchCourierSchedule:", error);
        return { success: false };
    }
};


export const createSchedule = async (courierId, days, copyFromPreviousWeek) => {
    try {
        const response = await fetch(`${API_URL}/co/schedule/${courierId}`, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ days, copyFromPreviousWeek }),
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in createSchedule:", error);
        return { success: false };
    }
};

export const updateSchedule = async (courierId, days) => {
    try {
        const response = await fetch(`${API_URL}/co/schedule/${courierId}`, {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ days }),
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in updateSchedule:", error);
        return { success: false };
    }
};

export const fetchLastWeekSchedule = async (courierId) => {
    try {
        const response = await fetch(`${API_URL}/co/schedule/last-week/${courierId}`, {
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in fetchLastWeekSchedule:", error);
        return { success: false };
    }
};



/////////////////////////////////////////
/////////////////Routes/////////////////
///////////////////////////////////////

// Create a New Route
export const createRoute = async (routeData) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/create`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(routeData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating route:', error);
        throw error;
    }
};

// Fetch All Routes with pagination and optional deleted status
export const fetchAllRoutes = async (page = 1, limit = 10, deleted = false) => {
    try {
        const response = await fetch(`${API_URL}/co/routes?deleted=${deleted}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching routes:', error);
        throw error;
    }
};

// Update an existing route
export const updateRoute = async (routeId, routeData) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/update/${routeId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(routeData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating route:', error);
        throw error;
    }
};

// Soft Delete a Route
export const deleteRoute = async (routeId) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/delete/${routeId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting route:', error);
        throw error;
    }
};

// Fetch Route by ID
export const fetchRouteById = async (routeId) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/details/${routeId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch route');
        }

        return handleResponse(response)
    } catch (error) {
        console.error('Error fetching route by ID:', error);
        return { success: false, error: error.message };
    }
};

// Get pickups by route id
export const fetchPickupsByRouteId = async (routeId) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups/${routeId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching pickups:', error);
        throw error;
    }
};

// Create Route Pickup
export const createRoutePickup = async (pickupData) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pickupData),  // Pass the pickup data as JSON
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error creating route pickup:', error);
        throw error;
    }
};

// Update Route Pickup
export const updateRoutePickup = async (pickupId, pickupData) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups/${pickupId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pickupData),  // Pass the updated pickup data
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error updating route pickup:', error);
        throw error;
    }
};

// Delete Route Pickup
export const deleteRoutePickup = async (pickupId, day) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups/${pickupId}?day=${day}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting route pickup:', error);
        throw error;
    }
};

// Get all Routes Pickups
export const fetchAllRoutesPickups = async (page = 1, limit = 10, deleted = false) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups?deleted=${deleted}&page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching routes:', error);
        throw error;
    }
}

// Update Pickup Order
export const updatePickupOrder = async (pickupId, pickupOrder, day) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups/update-order/${pickupId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pickupOrder, day }),  // Send the new order and the day
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error updating pickup order:', error);
        throw error;
    }
};

// Delete Route Pickup
export const deleteRoutePickupCompletely = async (pickupId, day) => {
    try {
        const response = await fetch(`${API_URL}/co/route-pickups/delete/${pickupId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting route pickup:', error);
        throw error;
    }
};

// Search Pickups
export const searchRoutePickups = async ({ pickupLocationId, dropOffLocationId, days = [], page = 1, limit = 10 }) => {
    try {
        // Build the query string dynamically
        const queryParams = new URLSearchParams();

        if (pickupLocationId) queryParams.append('pickupLocationId', pickupLocationId);
        if (dropOffLocationId) queryParams.append('dropOffLocationId', dropOffLocationId);
        if (days.length > 0) days.forEach(day => queryParams.append('day', day)); // Append each day for multiple days

        // Add pagination parameters
        queryParams.append('page', page);
        queryParams.append('limit', limit);

        // Send the request with the constructed query string
        const response = await fetch(`${API_URL}/co/route-pickups/search?${queryParams.toString()}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error searching pickups:', error);
        throw error;
    }
};


// Generate Route Pickups
export const generateRoute = async (routeId) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/generate-route/${routeId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error generating route pickups:', error);
        throw error;
    }
};


export const generateAllRoutes = async () => {
    try {
        const response = await fetch(`${API_URL}/co/routes/generate-all-routes`, {
            method: 'POST',       // or 'PUT', depending on how you design your endpoint
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;  // e.g. { success: true, message: '...', ... }
    } catch (error) {
        console.error('Error generating all routes:', error);
        throw error;
    }
};


// Fetch all generation history
export const fetchAllGenerationHistory = async () => {
    try {
        const response = await fetch(`${API_URL}/co/routes/generation-history`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        return data; // e.g. { success: true, data: [ ...historyRecords ] }
    } catch (error) {
        console.error('Error fetching all generation history:', error);
        throw error;
    }
};

// Fetch generation history for a single route
export const fetchRouteGenerationHistory = async (routeId) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/${routeId}/generation-history`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        return data; // e.g. { success: true, data: [ ...historyRecords ] }
    } catch (error) {
        console.error(`Error fetching generation history for route ${routeId}:`, error);
        throw error;
    }
};



export const fetchRouteBySearch = async (searchQuery) => {
    try {
        const response = await fetch(`${API_URL}/co/routes/fsearch?search=${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (data.success) {
            return data.routes.map(route => ({
                value: route._id,
                label: route.name,
            }));
        } else {
            throw new Error('Error fetching routes');
        }
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};



/////////////////////////////////////////
/////////////////Pickups////////////////
///////////////////////////////////////

// Create New Pickup
export const createPickup = async (pickupData) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/create`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pickupData),  // Pass the pickup data as JSON
        });

        return handleResponse(response)

    } catch (error) {
        console.error('Error creating pickup:', error);
        throw error;
    }
};

// Get all pickups
export const getAllPickups = async (page = 1, limit = 10, status = null) => {
    try {
        // Construct URL with dynamic query parameters
        const url = new URL(`${API_URL}/co/pickups`);
        url.searchParams.append('page', page);
        url.searchParams.append('limit', limit);

        // Allow status to be single or array
        if (Array.isArray(status)) {
            status.forEach((s) => url.searchParams.append('status', s));
        } else if (status) {
            url.searchParams.append('status', status);
        }

        const response = await fetch(url.toString(), {
            method: 'GET',
            credentials: 'include', // Include cookies for authentication if needed
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response); // Use your existing response handler
    } catch (error) {
        console.error('Error fetching pickups:', error);
        throw error;
    }
};


// Save Pickup Order
export const savePickupOrder = async (pickups) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/pickup-order`, {
            method: 'PUT',
            credentials: 'include',  // Include cookies for authentication if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pickups }),  // Send pickups in the body as JSON
        });

        return await handleResponse(response);  // Await the response handling function

    } catch (error) {
        console.error('Error saving pickup order:', error);
        throw error;
    }
};

// Transfer
export const transferPickup = async (data, id) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/transfer/${id}`, {
            method: 'PUT',
            credentials: 'include',  // Include cookies for authentication if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),  // Send pickups in the body as JSON
        });

        return await handleResponse(response);  // Await the response handling function

    } catch (error) {
        console.error('Error saving pickup order:', error);
        throw error;
    }
};

// Update pickups
export const updatePickup = async (data, id) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/update/${id}`, {
            method: 'PUT',
            credentials: 'include',  // Include cookies for authentication if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),  // Send pickups in the body as JSON
        });

        return await handleResponse(response);  // Await the response handling function

    } catch (error) {
        console.error('Error saving pickup order:', error);
        throw error;
    }
};

// Finish multiple pickups
export const finishPickups = async (data) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/finishPickups`, {
            method: 'PUT',
            credentials: 'include', // Include cookies if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // e.g. { pickupIds, action, reason }
        });

        return await handleResponse(response);
    } catch (error) {
        console.error('Error finishing pickups:', error);
        throw error;
    }
};


export const uploadPickupAttachements = async (formData, id) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/update/${id}`, {
            method: 'PUT',
            body: formData,  // Use formData to handle file uploads
            credentials: 'include',  // Include credentials (if using cookies for authentication)
        });

        return await handleResponse(response);  // Await the response handling function

    } catch (error) {
        console.error('Error saving pickup order:', error);
        throw error;
    }
};

export const deletePickupAttachements = async (attachmentId, id) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/attachments-delete/${id}`, {
            method: 'PUT',
            credentials: 'include',  // Include cookies for authentication if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ attachmentId }),
        });

        return await handleResponse(response);  // Await the response handling function

    } catch (error) {
        console.error('Error saving pickup order:', error);
        throw error;
    }
};

export const getPickupDetails = async (pickupId) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/details/${pickupId}`, {
            method: 'GET',  // Use PUT to match the updated route
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return await handleResponse(response);  // Await handleResponse to ensure response handling completes
    } catch (error) {
        console.error('Error canceling pickup:', error);
        throw error;
    }
}

// Search Pickups
export const searchPickups = async ({ pickupLocationId, dropOffLocationId, status, courier, route, attn, page = 1, limit = 10 }) => {
    try {
        // Build the query string dynamically
        const queryParams = new URLSearchParams();

        console.log({ pickupLocationId, dropOffLocationId, status, courier, route, attn, page, limit })

        if (pickupLocationId) queryParams.append('pickupLocationId', pickupLocationId);
        if (dropOffLocationId) queryParams.append('dropOffLocationId', dropOffLocationId);
        if (Array.isArray(status)) {
            status.forEach((s) => queryParams.append('status', s));
        } else if (status) {
            queryParams.append('status', status);
        }
        if (courier) queryParams.append('courier', courier);
        if (route) queryParams.append('route', route);
        if (Array.isArray(attn)) {
            attn.forEach((s) => queryParams.append('attn', s));
        } else if (attn) {
            queryParams.append('attn', attn);
        }

        // Add pagination parameters
        queryParams.append('page', page);
        queryParams.append('limit', limit);

        // Send the request with the constructed query string
        const response = await fetch(`${API_URL}/co/pickups/search?${queryParams.toString()}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error searching pickups:', error);
        throw error;
    }
};


// Delete Pickup
export const deletePickup = async (pickupId) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/${pickupId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting route pickup:', error);
        throw error;
    }
};

// Cancel Pickup
export const cancelPickup = async (pickupId, cancelReason) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/cancel/${pickupId}`, {
            method: 'PUT',  // Use PUT to match the updated route
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cancelReason })  // Pass cancelReason in the request body
        });

        return await handleResponse(response);  // Await handleResponse to ensure response handling completes
    } catch (error) {
        console.error('Error canceling pickup:', error);
        throw error;
    }
};

// Add a note to a pickup
export const addPickupNote = async (pickupId, noteText) => {
    try {
        const response = await fetch(`${API_URL}/co/pickups/notes/${pickupId}`, {
            method: 'POST',
            credentials: 'include',  // Include cookies if needed for auth
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ noteText, addedByModel: "User" }),
        });

        return await handleResponse(response);  // Await handleResponse to ensure response handling completes

    } catch (error) {
        console.error('Error adding note to pickup:', error);
        throw error;
    }
};

export const fetchCouriersBySearch = async (searchQuery) => {
    try {
        const response = await fetch(`${API_URL}/co/couriers/fsearch?search=${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (data.success) {
            console.log(data.couriers)

            return data.couriers.map(courier => ({
                value: courier._id,
                label: courier.name + " ┃ " + (courier?.route?.name ? courier?.route?.name : "No Route"),
            }));
        } else {
            throw new Error('Error fetching couriers');
        }
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};


/////////////////////////////////////////
////////////////Warehouses//////////////
///////////////////////////////////////

// 1. Fetch all Warehouses
export const fetchWarehouses = async (admin) => {
    try {
        const response = await fetch(`${API_URL}/warehouses/?adminPanel=${admin}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching warehouses:', error);
        throw error;
    }
};

// 2. Create Warehouse
export const createWarehouse = async (warehouseData) => {
    try {
        const response = await fetch(`${API_URL}/warehouses`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(warehouseData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error creating warehouse:', error);
        throw error;
    }
};

// 3. Update Warehouse
export const updateWarehouse = async (warehouseId, warehouseData) => {
    try {
        const response = await fetch(`${API_URL}/warehouses/${warehouseId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(warehouseData),
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error updating warehouse:', error);
        throw error;
    }
};

// 4. Delete Warehouse (Soft Delete)
export const deleteWarehouse = async (warehouseId) => {
    try {
        const response = await fetch(`${API_URL}/warehouses/${warehouseId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error deleting warehouse:', error);
        throw error;
    }
};


/////////////////////////////////////////
////////////////Inventory///////////////
///////////////////////////////////////

// 1. Receive Inventory
export const receiveInventory = async (data) => {
    try {
        const response = await fetch(`${API_URL}/inventory/receive`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error receiving inventory:', error);
        throw error;
    }
};

// 2. Deduct Inventory
export const deductInventory = async (data) => {
    try {
        const response = await fetch(`${API_URL}/inventory/deduct`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error deducting inventory:', error);
        throw error;
    }
};

// 3. Get Inventory Levels
export const fetchInventoryLevels = async (warehouseId, supplyItemId) => {
    try {
        const query = new URLSearchParams({ warehouseId, supplyItemId });
        const response = await fetch(`${API_URL}/inventory?${query}`, {
            method: 'GET',
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error fetching inventory levels:', error);
        throw error;
    }
};

// 4. Get Inventory Transaction History
export const fetchTransactionHistory = async (filters = {}) => {
    try {
        const query = new URLSearchParams(filters);
        const response = await fetch(`${API_URL}/inventory/transactions?${query}`, {
            method: 'GET',
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error fetching transaction history:', error);
        throw error;
    }
};

// Fetch transactions for a specific warehouse
export const fetchWarehouseTransactions = async (warehouseId, page = 1, limit = 10) => {
    try {
        const response = await fetch(`${API_URL}/inventory-transactions/transactions/${warehouseId}/?page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await handleResponse(response);
    } catch (error) {
        console.error("Error fetching warehouse transactions:", error);
        throw error;
    }
};

// Fetch all transactions (optional)
export const fetchAllTransactions = async (page = 1, limit = 10) => {
    try {
        const response = await fetch(`${API_URL}/inventory-transactions/?page=${page}&limit=${limit}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await handleResponse(response);
    } catch (error) {
        console.error("Error fetching all transactions:", error);
        throw error;
    }
};




// Email Templates

// 1. Fetch All Email Templates with Optional Filters and Pagination
export const fetchEmailTemplates = async (page = 1, limit = 10, is_active = null) => {
    try {
        const query = new URLSearchParams({ page, limit });
        if (is_active !== null) query.append('is_active', is_active);

        const response = await fetch(`${API_URL}/email-templates/?${query}`, {
            method: 'GET',
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error fetching email templates:', error);
        throw error;
    }
};

// 2. Fetch a Single Email Template by ID
export const fetchEmailTemplateById = async (templateId) => {
    try {
        const response = await fetch(`${API_URL}/email-templates/${templateId}`, {
            method: 'GET',
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error fetching email template by ID:', error);
        throw error;
    }
};

// 3. Create a New Email Template
export const createEmailTemplate = async (templateData) => {
    try {
        const response = await fetch(`${API_URL}/email-templates`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(templateData)
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error creating email template:', error);
        throw error;
    }
};

// 4. Update an Existing Email Template by ID
export const updateEmailTemplate = async (templateId, updatedData) => {
    try {
        const response = await fetch(`${API_URL}/email-templates/${templateId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error updating email template:', error);
        throw error;
    }
};

// 5. Delete an Email Template by ID
export const deleteEmailTemplate = async (templateId) => {
    try {
        const response = await fetch(`${API_URL}/email-templates/${templateId}`, {
            method: 'DELETE',
            credentials: 'include'
        });
        return handleResponse(response)
    } catch (error) {
        console.error('Error deleting email template:', error);
        throw error;
    }
};













// Reports
export const searchOrdersReports = async (filters, page = 1, limit = 50) => {
    const validPage = Number(page) || 1; // ✅ Ensure it's a valid number
    const validLimit = Number(limit) || 50;

    try {
        const params = new URLSearchParams({
            ...filters,
            page: validPage,
            limit: validLimit
        }).toString();

        console.log("🔹 Final API Call:", `${API_URL}/reports/generate?${params}`);

        const response = await fetch(`${API_URL}/reports/generate?${params}`, {
            method: 'GET',
            credentials: 'include'
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in searchOrdersReports:", error);
        return { success: false, data: [] };
    }
};





// Messages
export const getMessages = async (page = 1, limit = 10) => {
    const validPage = Number(page) || 1;
    const validLimit = Number(limit) || 10;

    try {
        const params = new URLSearchParams({
            page: validPage,
            limit: validLimit
        }).toString();

        console.log("🔹 Fetching Messages:", `${API_URL}/co/messages?${params}`);

        const response = await fetch(`${API_URL}/co/messages?${params}`, {
            method: 'GET',
            credentials: 'include'
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in getMessages:", error);
        return { success: false, data: [] };
    }
};

export const deleteMessage = async (messageId) => {
    try {
        console.log("🗑️ Deleting Message:", `${API_URL}/co/messages/${messageId}`);

        const response = await fetch(`${API_URL}/co/messages/${messageId}`, {
            method: 'DELETE',
            credentials: 'include'
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in deleteMessage:", error);
        return { success: false };
    }
};


export const sendMessage = async (messageData) => {
    try {
        const response = await fetch(`${API_URL}/co/messages/send`, {
            method: "POST",
            credentials: "include",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messageData),
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in sendMessage:", error);
        return { success: false };
    }
};








// Shippments and sendouts 

export const getShipments = async () => {
    try {
        const response = await fetch(`${API_URL}/shipments`, {
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in getShipments:", error);
        return { success: false };
    }
};

export const createShipment = async (shipmentData) => {
    try {
        const response = await fetch(`${API_URL}/shipments`, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(shipmentData),
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in createShipment:", error);
        return { success: false };
    }
};

export const markItemReturned = async (shipmentId, itemId) => {
    try {
        const response = await fetch(`${API_URL}/shipments/${shipmentId}/items/${itemId}/return`, {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in markItemReturned:", error);
        return { success: false };
    }
};

export const addShipmentNote = async (shipmentId, noteData) => {
    try {
        const response = await fetch(`${API_URL}/shipments/${shipmentId}/notes`, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(noteData),
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in addShipmentNote:", error);
        return { success: false };
    }
};


export const toggleItemReturn = async (shipmentId, itemId, returned) => {
    try {
        const response = await fetch(`${API_URL}/shipments/${shipmentId}/items/${itemId}/return`, {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ returned }),
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in toggleItemReturn:", error);
        return { success: false };
    }
};


export const deleteShipmentItem = async (shipmentId, itemId) => {
    try {
        const response = await fetch(`${API_URL}/shipments/${shipmentId}/items/${itemId}`, {
            method: "DELETE",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        return handleResponse(response);
    } catch (error) {
        console.error("❌ Error in deleteShipmentItem:", error);
        return { success: false };
    }
};

