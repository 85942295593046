// DuplicateOrderModal.jsx
import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Select,
    Spinner,
    Text,
    HStack,
    VStack,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    useToast,
    Tooltip,
    Divider,
    Heading,
    useDisclosure,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, NotAllowedIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { fetchOrderById, updateOrder, addNoteToOrder, fetchAllOrders, addOrderInternalNote } from '../../api'; // Adjust the import path as needed
import Loading from '../../components/Loading/Loading';

const DuplicateOrderModal = ({ isOpen, onClose, currentOrder }) => {
    const [orders, setOrders] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [loadingSelectedOrder, setLoadingSelectedOrder] = useState(false);

    const toast = useToast();

    useEffect(() => {
        if (isOpen) {
            loadOrders();
        }
    }, [isOpen]);

    // Fetch all orders excluding the current one
    const loadOrders = async () => {
        setLoadingOrders(true);
        try {
            const result = await fetchAllOrders();
            if (result.success) {
                const filteredOrders = (result.data.orders).filter(order => order._id !== currentOrder._id);
                setOrders(filteredOrders);
            } else {
                toast({
                    title: 'Error fetching orders.',
                    description: result.error || 'Unable to fetch orders.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
            toast({
                title: 'Error fetching orders.',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        setLoadingOrders(false);
    };

    // Handle selection change
    const handleSelectChange = async (e) => {
        const orderId = e.target.value;
        setSelectedOrderId(orderId);
        if (orderId) {
            setLoadingSelectedOrder(true);
            try {
                const result = await fetchOrderById(orderId);
                if (result.success) {
                    setSelectedOrder(result.data);
                } else {
                    toast({
                        title: 'Error fetching order details.',
                        description: result.error || 'Unable to fetch selected order.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error('Error fetching selected order:', error);
                toast({
                    title: 'Error fetching order details.',
                    description: 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
            setLoadingSelectedOrder(false);
        } else {
            setSelectedOrder(null);
        }
    };

    // Function to pair items from both orders
    const getPairedItems = () => {
        const currentItemsMap = new Map();
        currentOrder.requestedItems.forEach(item => {
            currentItemsMap.set(item.item._id, item);
        });

        const selectedItemsMap = new Map();
        if (selectedOrder) {
            selectedOrder.requestedItems.forEach(item => {
                selectedItemsMap.set(item.item._id, item);
            });
        }

        const allItemIds = new Set([
            ...currentItemsMap.keys(),
            ...selectedItemsMap.keys(),
        ]);

        const pairedItems = Array.from(allItemIds).map(itemId => ({
            itemId,
            currentItem: currentItemsMap.get(itemId),
            selectedItem: selectedItemsMap.get(itemId),
        }));

        return pairedItems;
    };

    const pairedItems = getPairedItems();

    // Handle the duplication process
    const handleDuplicate = async (orderToRemove) => {
        try {
            let referencedOrderId = orderToRemove === currentOrder._id ? selectedOrder._id : orderToRemove;
            const note = `This is a duplicate Order. The original order is here <a href="${process.env.REACT_APP_APP_URL}/order-details/${referencedOrderId}">${referencedOrderId}</a>`;


            // Add Note to internal notes
            await addOrderInternalNote(orderToRemove, note); // Call the centralized API function

            // Finishe Order

            const updateOrderDetails = await updateOrder(orderToRemove, { finished: true })
            if (updateOrderDetails.success) {

                // Provide success feedback
                toast({
                    title: 'Order Duplicated.',
                    description: `The duplicated order has been removed.`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                // Optionally, you can refresh the orders list or perform other actions here
                onClose();
            } else {
                toast({
                    title: 'Order cannot be removed.',
                    description: updateOrderDetails.error,
                    status: 'danger',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Duplication Failed.',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="full" scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Duplicate Order</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            {/* Order Selection */}
                            <Select placeholder="Select the duplicated order" onChange={handleSelectChange}>
                                {orders.map(order => (
                                    <option key={order._id} value={order._id}>
                                        {order.clientName}
                                    </option>
                                ))}
                            </Select>

                            {/* Loading Indicator for Selected Order */}
                            {loadingSelectedOrder && (
                                <VStack mt={10} justify="center">
                                    <Loading />
                                    <Text mt={4} mb={4} fontWeight={'light'} fontSize='4xl'>Loading Compare between orders, hold up</Text>
                                </VStack>
                            )}

                            {/* Comparison Table */}
                            {selectedOrder && !loadingSelectedOrder && (
                                <>
                                    <Box borderWidth="1px" borderRadius="md" p={4} overflowX="auto">
                                        <Text fontWeight="bold" mb={2}>Comparison of Requested Items</Text>
                                        <Table variant="simple" size="sm">
                                            <Thead>
                                                {/* Header Row with Order Titles and Separator */}
                                                <Tr>
                                                    {/* Current Order Header */}
                                                    <Th colSpan={3} textAlign="center">
                                                        Current Order ( {currentOrder.clientName} )
                                                        <Tooltip label="Add duplication note to the current order" aria-label="Remove Tooltip">
                                                            <Button
                                                                size="xs"
                                                                colorScheme="red"
                                                                leftIcon={<CloseIcon />}
                                                                onClick={() => {
                                                                    if (window.confirm(`Are you sure you want to remove this order from ( ${currentOrder.clientName} ) ?`)) {
                                                                        handleDuplicate(currentOrder._id);
                                                                    }
                                                                }}
                                                            >
                                                                Remove this
                                                            </Button>
                                                        </Tooltip>


                                                        <Box borderWidth="1px" mt={4} borderRadius="lg" p={6} boxShadow="sm" bg="white">
                                                            <Heading size="md" mb={5} color="gray.700" >Order Details</Heading>

                                                            <HStack align="start" spacing={10}>
                                                                {/* Business Details */}
                                                                <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                                                                    <Heading size="sm" color="gray.400">Business Information</Heading>
                                                                    {currentOrder?.business ? (
                                                                        <>
                                                                            <HStack>
                                                                                <Text>{currentOrder?.business.name}</Text>
                                                                            </HStack>
                                                                            <HStack>
                                                                                <Text>{currentOrder?.business.address.address1 + ", " + (currentOrder?.business.address.address2 ? currentOrder?.business.address.address2 + ", " : '') + currentOrder?.business.address.city + ", " + currentOrder?.business.address.state + ", " + currentOrder?.business.address.zip}</Text>
                                                                            </HStack>

                                                                            {currentOrder?.orderTakenBy &&
                                                                                <HStack>
                                                                                    <Text fontWeight="bold">Order By</Text>
                                                                                    <Text>{currentOrder?.orderTakenBy.firstName}</Text>
                                                                                </HStack>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <Text color="gray.500">No business information available.</Text>
                                                                    )}
                                                                </VStack>

                                                                {/* Client-Entered Details */}
                                                                <VStack align="start" spacing={3} flex="1">
                                                                    <Heading size="sm" color="gray.400">Client-Entered Information</Heading>
                                                                    <HStack>
                                                                        <Text>{currentOrder?.clientName || "N/A"}</Text>
                                                                    </HStack>
                                                                    <HStack>
                                                                        <Text>{(currentOrder?.clientAddress.address || "N/A") + ", " + (currentOrder?.clientAddress.city || "N/A") + ", " + (currentOrder?.clientAddress.state || "N/A") + ", " + (currentOrder?.clientAddress.zip || "N/A")}</Text>
                                                                    </HStack>
                                                                </VStack>

                                                            </HStack>
                                                        </Box>
                                                        <br />
                                                        <br />
                                                        Client Notes
                                                        <br />
                                                        <br />
                                                        {currentOrder?.note || "Client didn't leave any notes"}
                                                        <br />
                                                        <br />



                                                    </Th>
                                                    {/* Separator */}
                                                    <Th width="1px" borderLeft="20px solid #CCC"></Th>
                                                    {/* Selected Order Header */}
                                                    <Th colSpan={3} textAlign="center">
                                                        Selected Order ( {selectedOrder.clientName} )
                                                        <Tooltip label="Add duplication note to the current order" aria-label="Remove Tooltip">
                                                            <Button
                                                                size="xs"
                                                                colorScheme="red"
                                                                leftIcon={<CloseIcon />}
                                                                onClick={() => {
                                                                    if (window.confirm(`Are you sure you want to remove this order from ( ${selectedOrder.clientName} ) ?`)) {
                                                                        handleDuplicate(selectedOrder._id);
                                                                    }
                                                                }}
                                                            >
                                                                Remove this
                                                            </Button>
                                                        </Tooltip>

                                                        <Box borderWidth="1px" mt={4} borderRadius="lg" p={6} boxShadow="sm" bg="white">
                                                            <Heading size="md" mb={5} color="gray.700" >Order Details</Heading>

                                                            <HStack align="start" spacing={10}>
                                                                {/* Business Details */}
                                                                <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                                                                    <Heading size="sm" color="gray.400">Business Information</Heading>
                                                                    {selectedOrder?.business ? (
                                                                        <>
                                                                            <HStack>
                                                                                <Text>{selectedOrder?.business.name}</Text>
                                                                            </HStack>
                                                                            <HStack>
                                                                                <Text>{selectedOrder?.business.address.address1 + ", " + (selectedOrder?.business.address.address2 ? selectedOrder?.business.address.address2 + ", " : '') + selectedOrder?.business.address.city + ", " + selectedOrder?.business.address.state + ", " + selectedOrder?.business.address.zip}</Text>
                                                                            </HStack>

                                                                            {selectedOrder?.orderTakenBy &&
                                                                                <HStack>
                                                                                    <Text fontWeight="bold">Order By</Text>
                                                                                    <Text>{selectedOrder?.orderTakenBy.firstName}</Text>
                                                                                </HStack>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <Text color="gray.500">No business information available.</Text>
                                                                    )}
                                                                </VStack>

                                                                {/* Client-Entered Details */}
                                                                <VStack align="start" spacing={3} flex="1">
                                                                    <Heading size="sm" color="gray.400">Client-Entered Information</Heading>
                                                                    <HStack>
                                                                        <Text>{selectedOrder?.clientName || "N/A"}</Text>
                                                                    </HStack>
                                                                    <HStack>
                                                                        <Text>{(selectedOrder?.clientAddress.address || "N/A") + ", " + (selectedOrder?.clientAddress.city || "N/A") + ", " + (selectedOrder?.clientAddress.state || "N/A") + ", " + (selectedOrder?.clientAddress.zip || "N/A")}</Text>
                                                                    </HStack>
                                                                </VStack>

                                                            </HStack>
                                                        </Box>
                                                        <br />
                                                        <br />
                                                        Client Notes
                                                        <br />
                                                        <br />
                                                        {selectedOrder?.note || "Client didn't leave any notes"}
                                                        <br />
                                                        <br />


                                                    </Th>
                                                </Tr>
                                                {/* Column Titles */}
                                                <Tr>
                                                    {/* Current Order Columns */}
                                                    <Th>Item Name</Th>
                                                    <Th>Size</Th>
                                                    <Th>Quantity Requested</Th>
                                                    {/* Separator */}
                                                    <Th width="1px" borderLeft="20px solid #CCC"></Th>
                                                    {/* Selected Order Columns */}
                                                    <Th>Item Name</Th>
                                                    <Th>Size</Th>
                                                    <Th>Quantity Requested</Th>
                                                    {/* Actions Column Title */}
                                                    <Th></Th> {/* Empty header for actions */}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {pairedItems.map(({ itemId, currentItem, selectedItem }) => {
                                                    // Determine if the item is uncommon
                                                    const isUncommon = !currentItem || !selectedItem;
                                                    const uniqueToCurrent = currentItem && !selectedItem;

                                                    return (
                                                        <Tr
                                                            key={itemId}
                                                            bg={
                                                                isUncommon
                                                                    ? uniqueToCurrent
                                                                        ? 'red.100' // Items only in current order
                                                                        : 'blue.100' // Items only in selected order
                                                                    : 'transparent' // Common items
                                                            }
                                                        >
                                                            {/* Current Order Item Details */}
                                                            <Td>{currentItem ? currentItem.item.name : '-'}</Td>
                                                            <Td>{currentItem ? currentItem.item.size : '-'}</Td>
                                                            <Td>{currentItem ? currentItem.quantityRequested : '-'}</Td>
                                                            {/* Separator */}
                                                            <Td width="1px" borderLeft="20px solid #CCC"></Td>

                                                            {/* Selected Order Item Details */}
                                                            <Td>{selectedItem ? selectedItem.item.name : '-'}</Td>
                                                            <Td>{selectedItem ? selectedItem.item.size : '-'}</Td>
                                                            <Td>{selectedItem ? selectedItem.quantityRequested : '-'}</Td>

                                                            {/* Actions Column - Leave empty for item-level actions if any */}
                                                            <Td></Td>
                                                        </Tr>
                                                    );
                                                })}

                                                {/* Actions Row */}
                                                <Tr>
                                                    {/* Empty cells for alignment */}
                                                    <Td colSpan={3} textAlign="center"></Td>
                                                    <Td></Td>
                                                    <Td colSpan={3} textAlign="center"></Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </Box>

                                    {/* Compare Info Chart */}


                                    <HStack>
                                        <Box
                                            width="10px"
                                            height="10px"
                                            bg="blue.100"
                                            borderRadius="full"
                                        />
                                        <Text>Item <strong>not exists in the current order</strong> and <strong>exists in the selected order</strong>.</Text>
                                    </HStack>
                                    <HStack>
                                        <Box
                                            width="10px"
                                            height="10px"
                                            bg="red.100"
                                            borderRadius="full"
                                        />
                                        <Text>Item <strong>exists in the current order</strong> and <strong>not exists in the selected order</strong></Text>
                                    </HStack>
                                    <HStack>
                                        <Box
                                            width="10px"
                                            height="10px"
                                            bg="white.100"
                                            borderRadius="full"
                                            border={"2px solid #ccc"}
                                        />
                                        <Text>Item <strong>exists in the both orders</strong>.</Text>
                                    </HStack>

                                </>
                            )}

                        </VStack>



                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        {/* You can add additional actions here if needed */}
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    );
};

export default DuplicateOrderModal;
