import React, { useEffect, useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    Stat,
    StatLabel,
    StatNumber,
    SimpleGrid,
    Spinner,
    Alert,
    AlertIcon,
    Text,
    Icon,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Card,
    CardHeader,
    CardBody
} from '@chakra-ui/react';
import { FaTruck, FaCheckCircle, FaCalendarAlt, FaBuilding, FaBoxOpen, FaClock } from 'react-icons/fa';
import { fetchOrdersWidgets } from '../../api';
import { hasPermission } from '../../Utils';

const ClientsOrdersWidgets = () => {
    const [ongoingOrders, setOngoingOrders] = useState(0);
    const [finishedOrders, setFinishedOrders] = useState(0);
    const [monthlyOrders, setMonthlyOrders] = useState(0);
    const [topItems, setTopItems] = useState([]);
    const [topFacilities, setTopFacilities] = useState([]);
    const [averageFinishingTime, setAverageFinishingTime] = useState("N/A");
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [errorOrders, setErrorOrders] = useState(null);

    const loadOrdersWidgetData = async () => {
        try {
            const result = await fetchOrdersWidgets();
            if (result.success) {
                setOngoingOrders(result.data.ongoingOrders);
                setFinishedOrders(result.data.finishedOrders);
                setMonthlyOrders(result.data.monthlyOrders);
                setTopItems(result.data.topItems || []);
                setTopFacilities(result.data.topFacilities || []);
                setAverageFinishingTime(result.data.averageFinishingTime || "N/A");

            } else {
                setErrorOrders(result.message || 'Failed to load widget data');
            }
        } catch (err) {
            setErrorOrders('Failed to load widget data');
        }
        setLoadingOrders(false);
    };

    useEffect(() => {
        if (hasPermission("clientOrderWidget")) {
            loadOrdersWidgetData();
        }
    }, []);

    return (

        hasPermission("clientOrderWidget") && (
            <>
                {loadingOrders && (
                    <Box textAlign="center" py={10}>
                        <Spinner size="xl" />
                    </Box>
                )}

                {errorOrders && (
                    <Alert status="error">
                        <AlertIcon />
                        {errorOrders}
                    </Alert>
                )}

                {!loadingOrders && !errorOrders && (
                    <Card w={'full'}>
                        <CardHeader>
                            <Heading size='md'> Clients Orders Overview</Heading>
                        </CardHeader>
                        <CardBody>
                            <SimpleGrid columns={[1, 4]} spacing={8}>
                                {/* Ongoing Orders */}
                                <Box
                                    borderRadius="md"
                                    boxShadow="md"
                                    p={6}
                                    bgGradient="linear(to-r, blue.50, blue.100)"
                                    mt={8}
                                >
                                    <HStack spacing={4}>
                                        <Icon as={FaTruck} w={8} h={8} color="blue.600" />
                                        <Stat>
                                            <StatLabel fontWeight="medium">Ongoing Orders</StatLabel>
                                            <StatNumber fontSize="2xl" color="blue.700">{ongoingOrders}</StatNumber>
                                        </Stat>
                                    </HStack>
                                </Box>

                                {/* Finished Orders */}
                                <Box
                                    borderRadius="md"
                                    boxShadow="md"
                                    p={6}
                                    bgGradient="linear(to-r, green.50, green.100)"
                                    mt={8}
                                >
                                    <HStack spacing={4}>
                                        <Icon as={FaCheckCircle} w={8} h={8} color="green.600" />
                                        <Stat>
                                            <StatLabel fontWeight="medium">Finished Orders</StatLabel>
                                            <StatNumber fontSize="2xl" color="green.700">{finishedOrders}</StatNumber>
                                        </Stat>
                                    </HStack>
                                </Box>

                                {/* Monthly Orders */}
                                <Box
                                    borderRadius="md"
                                    boxShadow="md"
                                    p={6}
                                    bgGradient="linear(to-r, purple.50, purple.100)"
                                    mt={8}
                                >
                                    <HStack spacing={4}>
                                        <Icon as={FaCalendarAlt} w={8} h={8} color="purple.600" />
                                        <Stat>
                                            <StatLabel fontWeight="medium">This Month's Orders so far</StatLabel>
                                            <StatNumber fontSize="2xl" color="purple.700">{monthlyOrders}</StatNumber>
                                        </Stat>
                                    </HStack>
                                </Box>

                                {/* Average Finishing Time */}
                                <Box
                                    borderRadius="md"
                                    boxShadow="md"
                                    p={6}
                                    bgGradient="linear(to-r, yellow.50, yellow.100)"
                                    mt={8}
                                >
                                    <HStack spacing={4}>
                                        <Icon as={FaClock} w={8} h={8} color="yellow.600" />
                                        <Stat>
                                            <StatLabel fontWeight="medium">Avg Order Finishing Time This Month</StatLabel>
                                            <StatNumber fontSize="2xl" color="yellow.700">
                                                {averageFinishingTime !== "N/A"
                                                    ? `${averageFinishingTime} hours`
                                                    : "N/A"}
                                            </StatNumber>
                                        </Stat>
                                    </HStack>
                                </Box>


                            </SimpleGrid>


                            {/* Put the two tables side by side */}
                            <SimpleGrid columns={[1, 2]} spacing={10} mt={10}>
                                <Box p={6} borderRadius="md" boxShadow="lg" bg="white">
                                    <Heading size="md" mb={4}>
                                        <HStack>
                                            <Icon as={FaBuilding} />
                                            <Text>Top 3 Requesting Facilities (This Month)</Text>
                                        </HStack>
                                    </Heading>
                                    {topFacilities && topFacilities.length > 0 ? (
                                        <Table variant="striped" colorScheme="gray">
                                            <Thead>
                                                <Tr>
                                                    <Th>Facility Name</Th>
                                                    <Th isNumeric>Requested</Th>
                                                    <Th isNumeric>Sent</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {topFacilities.map((facility) => (
                                                    <Tr key={facility.facilityId}>
                                                        <Td>{facility.facilityName || 'Unnamed Facility'}</Td>
                                                        <Td isNumeric>{facility.totalRequested}</Td>
                                                        <Td isNumeric>{facility.totalSent}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    ) : (
                                        <Box>No facilities data for this month.</Box>
                                    )}
                                </Box>

                                <Box p={6} borderRadius="md" boxShadow="lg" bg="white">
                                    <Heading size="md" mb={4}>
                                        <HStack>
                                            <Icon as={FaBoxOpen} />
                                            <Text>Top 3 Requested Items (This Month)</Text>
                                        </HStack>
                                    </Heading>
                                    {topItems && topItems.length > 0 ? (
                                        <Table variant="striped" colorScheme="gray">
                                            <Thead>
                                                <Tr>
                                                    <Th>Item Name</Th>
                                                    <Th isNumeric>Requested</Th>
                                                    <Th isNumeric>Sent</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {topItems.map((item) => (
                                                    <Tr key={item.itemId}>
                                                        <Td>{item.itemName || 'Unnamed Item'}</Td>
                                                        <Td isNumeric>{item.totalRequested}</Td>
                                                        <Td isNumeric>{item.totalSent}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    ) : (
                                        <Box>No items requested this month.</Box>
                                    )}
                                </Box>
                            </SimpleGrid>
                        </CardBody>
                    </Card>
                )}
            </>
        )
    );
};

export default ClientsOrdersWidgets;
