import { useState, useEffect } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton,
    Button, FormControl, FormLabel, Input, Checkbox, VStack, HStack, useToast, Spinner
} from "@chakra-ui/react";
import { FaCalendarCheck } from "react-icons/fa";
import { createSchedule, fetchCourierSchedule, fetchLastWeekSchedule, updateSchedule } from "../../api";

const ScheduleModal = ({ isOpen, onClose, courier }) => {
    const [schedule, setSchedule] = useState([]);
    const [loading, setLoading] = useState(false);
    const [copyFromPreviousWeek, setCopyFromPreviousWeek] = useState(false);
    const [existingSchedule, setExistingSchedule] = useState(null); // Store existing schedule

    const toast = useToast();

    useEffect(() => {
        if (courier) {
            fetchSchedule();
        }
    }, [courier]);


    const getWeekday = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", { weekday: "long", timeZone: "America/Los_Angeles" }).format(date);
    };

    const generateFullWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

        // Force start from Monday
        let daysUntilMonday;
        if (dayOfWeek === 0) {
            daysUntilMonday = 6; // If it's Sunday, go back 6 days
        } else {
            daysUntilMonday = dayOfWeek - 1; // Otherwise, go back dayOfWeek-1 days
        }

        const currentMonday = new Date(today);
        currentMonday.setDate(today.getDate() - daysUntilMonday);

        const fullWeek = [...Array(7)].map((_, index) => {
            const date = new Date(currentMonday);
            date.setDate(currentMonday.getDate() + index);

            return {
                date: date.toISOString().split("T")[0], // YYYY-MM-DD
                startTime: "",
                endTime: "",
                isDayOff: false,
                locked: false,
                canceled: false
            };
        });

        console.log("Generated Week (Los Angeles Time):", fullWeek.map(d => ({
            date: d.date,
            weekday: getWeekday(d.date) // Assuming you have a getWeekday function
        })));

        return fullWeek;
    };

    const fetchSchedule = async () => {
        setLoading(true);
        try {
            const data = await fetchCourierSchedule(courier._id);
            const fetchedDays = data.success ? data.data.days : [];
            console.log("Fetched Days:", fetchedDays);

            // Generate full week (Monday → Sunday)
            const fullWeek = generateFullWeek();

            // Normalize fetched dates (convert `T00:00:00.000Z` to `YYYY-MM-DD`)
            const normalizedFetchedDays = fetchedDays.map(day => ({
                ...day,
                date: new Date(day.date).toISOString().split("T")[0], // Convert to YYYY-MM-DD
            }));

            // Merge fetched data with full week structure
            const updatedSchedule = fullWeek.map(day => {
                const existingDay = normalizedFetchedDays.find(d => d.date === day.date);
                return existingDay ? { ...day, ...existingDay } : day; // Use stored schedule or keep empty
            });

            console.log("Updated Schedule:", updatedSchedule); // Debug output
            setSchedule(updatedSchedule);
            setExistingSchedule(fetchedDays.length > 0 ? data.data : null);
        } catch (error) {
            console.error("❌ Error fetching schedule:", error);
            setSchedule(generateFullWeek()); // Default to empty schedule if error
            setExistingSchedule(null);
        }
        setLoading(false);
    };



    const isLocked = (date, startTime) => {
        if (!startTime) return false;
        const shiftStart = new Date(`${date}T${startTime}`);
        const now = new Date();
        return now >= new Date(shiftStart.getTime() - 30 * 60000); // 30 min before shift
    };

    const handleSickLeave = (index) => {
        const updatedSchedule = [...schedule];
        updatedSchedule[index].canceled = true;
        updatedSchedule[index].startTime = "";
        updatedSchedule[index].endTime = "";
        setSchedule(updatedSchedule);
    };



    const handleScheduleChange = (index, field, value) => {
        const updatedSchedule = [...schedule];
        updatedSchedule[index][field] = value;
        setSchedule(updatedSchedule);
    };

    const isPastDate = (date) => {
        const today = new Date().setHours(0, 0, 0, 0); // Remove time for accurate date comparison
        const shiftDate = new Date(date).setHours(0, 0, 0, 0);
        return shiftDate < today; // Disable if it's in the past
    };

    const handleSubmit = async () => {
        setLoading(true);

        // Filter out past days before submission (but keep them in UI)
        const today = new Date().setHours(0, 0, 0, 0);
        const filteredSchedule = schedule.filter(day => new Date(day.date).setHours(0, 0, 0, 0) >= today);

        if (filteredSchedule.length === 0) {
            toast({ title: "No valid shifts to save.", status: "warning", duration: 3000 });
            setLoading(false);
            return;
        }

        try {
            let data;
            if (existingSchedule) {
                data = await updateSchedule(courier._id, filteredSchedule);
            } else {
                data = await createSchedule(courier._id, filteredSchedule, copyFromPreviousWeek);
            }

            if (data.success) {
                toast({ title: "Schedule saved successfully!", status: "success", duration: 3000 });

                // ✅ Fetch full week schedule again to ensure all days (past & future) show
                await fetchSchedule();

                onClose();
            } else {
                toast({ title: data.message, status: "error", duration: 3000 });
            }
        } catch (error) {
            console.error("❌ Error saving schedule:", error);
            toast({ title: "Failed to save schedule.", status: "error", duration: 3000 });
        }

        setLoading(false);
    };




    const handleCopyLastWeekSchedule = async () => {
        setLoading(true);
        try {
            const data = await fetchLastWeekSchedule(courier._id);

            if (!data.success || data.data.length === 0) {
                toast({ title: "No schedule found for last week.", status: "warning", duration: 3000 });
                setLoading(false);
                return;
            }

            console.log("Fetched Last Week's Data:", data.data); // Debugging

            // Create a mapping of last week's days by weekday name
            const lastWeekMap = {};
            data.data.forEach(day => {
                const weekday = new Intl.DateTimeFormat("en-US", { weekday: "long", timeZone: "America/Los_Angeles" }).format(new Date(day.date));
                lastWeekMap[weekday] = day; // Store this day by its weekday
            });

            setSchedule(prevSchedule => {
                const updatedSchedule = prevSchedule.map(day => {
                    const weekday = new Intl.DateTimeFormat("en-US", { weekday: "long", timeZone: "America/Los_Angeles" }).format(new Date(day.date));
                    const lastWeekDay = lastWeekMap[weekday]; // Find last week's matching weekday

                    return lastWeekDay
                        ? { ...day, startTime: lastWeekDay.startTime, endTime: lastWeekDay.endTime, isDayOff: lastWeekDay.isDayOff }
                        : day; // If no match, keep it unchanged
                });

                console.log("Updated Schedule After Copy:", updatedSchedule); // Debugging
                return updatedSchedule; // Ensure state updates properly
            });

            toast({ title: "Last week's schedule copied!", status: "success", duration: 3000 });

        } catch (error) {
            console.error("❌ Error copying last week's schedule:", error);
            toast({ title: "Failed to copy last week's schedule.", status: "error", duration: 3000 });
        }
        setLoading(false);
    };



    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Manage Schedule for {courier?.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {loading ? <Spinner size="lg" /> : (
                        <VStack spacing={4}>
                            {console.log("Sorted Modal Rendering Order:", schedule.map(d => ({
                                date: d.date,
                                weekday: new Intl.DateTimeFormat("en-US", { weekday: "long", timeZone: "America/Los_Angeles" }).format(new Date(d.date + "T00:00:00")) // Added "T00:00:00"
                            })))}

                            {schedule.map((day, index) => {
                                const formattedDate = new Intl.DateTimeFormat("en-US", {
                                    weekday: "long",
                                    month: "short",
                                    day: "numeric",
                                    timeZone: "America/Los_Angeles"
                                }).format(new Date(day.date + "T00:00:00")); // Added "T00:00:00"


                                return (
                                    <HStack key={index} width="100%">
                                        <FormLabel flex="1"><b>{formattedDate}</b></FormLabel>
                                        <Input
                                            type="time"
                                            value={day.startTime || ""}
                                            isDisabled={day.isDayOff || isPastDate(day.date) || isLocked(day.date, day.startTime)}
                                            onChange={(e) => handleScheduleChange(index, "startTime", e.target.value)}
                                        />
                                        <Input
                                            type="time"
                                            value={day.endTime || ""}
                                            isDisabled={day.isDayOff || isPastDate(day.date) || isLocked(day.date, day.startTime)}
                                            onChange={(e) => handleScheduleChange(index, "endTime", e.target.value)}
                                        />
                                        <Checkbox
                                            isChecked={day.isDayOff}
                                            isDisabled={isPastDate(day.date)} // Disable marking past dates
                                            onChange={(e) => handleScheduleChange(index, "isDayOff", e.target.checked)}
                                        >
                                            Day Off
                                        </Checkbox>
                                        {!day.isDayOff && isLocked(day.date, day.startTime) && !day.canceled && (
                                            <Button size="sm" colorScheme="red" onClick={() => handleSickLeave(index)}>
                                                Call Sick
                                            </Button>
                                        )}
                                    </HStack>
                                );
                            })}
                        </VStack>
                    )}
                </ModalBody>




                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button colorScheme="blue" onClick={handleSubmit} isLoading={loading} leftIcon={<FaCalendarCheck />}>
                        Save Schedule
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleCopyLastWeekSchedule}
                        isLoading={loading}
                    >
                        Copy Last Week’s Schedule
                    </Button>


                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ScheduleModal;
