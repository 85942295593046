import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, VStack, useToast } from "@chakra-ui/react";
import { createShipment } from "../../api";

const AddShipmentModal = ({ isOpen, onClose }) => {
    const [clientName, setClientName] = useState("");
    const [clientAddress, setClientAddress] = useState("");
    const [items, setItems] = useState([{ equipmentName: "", serialNumber: "" }]);
    const toast = useToast();

    const handleAddItem = () => {
        setItems([...items, { equipmentName: "", serialNumber: "" }]);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const handleSubmit = async () => {
        const response = await createShipment({ clientName, clientAddress, items });
        if (response.success) {
            toast({ title: "Shipment added successfully", status: "success" });
            onClose();
        } else {
            toast({ title: "Error adding shipment", status: "error" });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add New Shipment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={3}>
                        <Input placeholder="Client Name" value={clientName} onChange={(e) => setClientName(e.target.value)} />
                        <Input placeholder="Client Address (Optional)" value={clientAddress} onChange={(e) => setClientAddress(e.target.value)} />
                        {items.map((item, index) => (
                            <VStack key={index} spacing={2} w="full">
                                <Input placeholder="Equipment Name" value={item.equipmentName} onChange={(e) => handleItemChange(index, "equipmentName", e.target.value)} />
                                <Input placeholder="Serial Number" value={item.serialNumber} onChange={(e) => handleItemChange(index, "serialNumber", e.target.value)} />
                            </VStack>
                        ))}
                        <Button onClick={handleAddItem} size="sm">+ Add Another Item</Button>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleSubmit}>Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddShipmentModal;
