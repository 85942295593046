import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Center,
    Alert,
    AlertIcon,
    AlertDescription,
    Badge,
    Select,
    Divider,
} from '@chakra-ui/react';
import { ViewIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import Pagination from '../../components/Pagination';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { fetchAllRoutes, createRoute, updateRoute, deleteRoute, fetchAllCouriers, fetchAllGenerationHistory, fetchRouteGenerationHistory } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import { useNavigate } from 'react-router-dom';
import CreatePickupModal from './CreatePickupModal';
import { FaList, FaPlus } from 'react-icons/fa';
import { hasPermission } from '../../Utils';
import RouteGenerator from './RouteGenerator';
import GenerateAllRoutesButton from './GenerateAllRoutesButton';

const AllRoutes = () => {
    const navigate = useNavigate();

    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
    const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const { isOpen: isCourierModalOpen, onOpen: onOpenCourierModal, onClose: onCloseCourierModal } = useDisclosure();
    const { isOpen: isPickupModalOpen, onOpen: onOpenPickupModal, onClose: onClosePickupModal } = useDisclosure();
    // **New** modals for generation history
    const { isOpen: isAllHistoryOpen, onOpen: onOpenAllHistory, onClose: onCloseAllHistory } = useDisclosure();
    const { isOpen: isRouteHistoryOpen, onOpen: onOpenRouteHistory, onClose: onCloseRouteHistory } = useDisclosure();

    const [routes, setRoutes] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [selectedCourier, setSelectedCourier] = useState(''); // For assigning courier
    const [newRoute, setNewRoute] = useState({ name: '', cities: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);  // Global error handling
    const [errorModal, setErrorModal] = useState(null); // Error state for modal
    // **New** state for generation histories
    const [allGenerationHistory, setAllGenerationHistory] = useState([]);
    const [routeGenerationHistory, setRouteGenerationHistory] = useState([]);


    const itemsPerPage = 25;

    useEffect(() => {
        getRoutes();
        getCouriers(); // Fetch couriers
    }, []);

    const getRoutes = async (page = 1) => {
        setLoading(true);
        setError(null);  // Clear any previous error
        try {
            const response = await fetchAllRoutes(page, itemsPerPage);
            if (response.success) {
                setRoutes(response.data.routes);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load routes');
        }
        setLoading(false);
    };


    // -----------------------------
    //  FETCH & SHOW ALL HISTORY
    // -----------------------------
    const handleViewAllHistory = async () => {
        try {
            setLoadingProcess(true);
            const response = await fetchAllGenerationHistory();
            if (response.success) {
                setAllGenerationHistory(response.data);
                onOpenAllHistory();
            } else {
                // handle error
            }
        } catch (error) {
            console.error('Error fetching all generation history:', error);
        } finally {
            setLoadingProcess(false);
        }
    };

    // -----------------------------
    //  FETCH & SHOW A SINGLE ROUTE'S HISTORY
    // -----------------------------
    const handleViewRouteHistory = async (route) => {
        try {
            setSelectedRoute(route);
            setLoadingProcess(true);
            const response = await fetchRouteGenerationHistory(route._id);
            if (response.success) {
                setRouteGenerationHistory(response.data);
                onOpenRouteHistory();
            } else {
                // handle error
            }
        } catch (error) {
            console.error('Error fetching route generation history:', error);
        } finally {
            setLoadingProcess(false);
        }
    };



    const getCouriers = async () => {
        try {
            const response = await fetchAllCouriers(); // Fetch couriers from the API
            if (response.success) {
                setCouriers(response.data.couriers);
            } else {
                setError('Failed to fetch couriers');
            }
        } catch (error) {
            setError('Error loading couriers');
        }
    };

    const handleAssignCourier = async () => {
        if (!selectedCourier) {
            setErrorModal('Please select a courier');
            return;
        }
        setLoadingProcess(true);
        try {
            const response = await updateRoute(selectedRoute._id, { courier: selectedCourier });
            if (response.success) {
                setRoutes(routes.map(route => route._id === selectedRoute._id ? response.data : route));
                onCloseCourierModal();
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal('Error assigning courier');
        }
        setLoadingProcess(false);
    };

    const handleModalClose = () => {
        setErrorModal(null);
        onCloseCreateModal();
        onCloseEditModal();
        setLoadingProcess(false);
    };

    const handleCreateRoute = async () => {
        setLoadingProcess(true);
        setErrorModal(null);  // Clear modal error

        // Validate required fields
        if (!newRoute.name || !newRoute.cities) {
            setErrorModal('Please fill in all required fields.');
            setLoadingProcess(false);
            return;
        }

        try {
            const response = await createRoute(newRoute);
            if (response.success) {
                setRoutes([response.data, ...routes]);
                onCloseCreateModal();
                setNewRoute({ name: '', cities: '' })
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal('Error creating route');
        }
        setLoadingProcess(false);
    };

    const handleEditRoute = async () => {
        setLoadingProcess(true);
        setErrorModal(null);  // Clear modal error

        // Validate required fields
        if (!selectedRoute.name || !selectedRoute.cities) {
            setErrorModal('Please fill in all required fields.');
            setLoadingProcess(false);
            return;
        }

        try {
            const response = await updateRoute(selectedRoute._id, selectedRoute);
            if (response.success) {
                setRoutes(routes.map(route => route._id === selectedRoute._id ? response.data : route));
                onCloseEditModal();
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal('Error updating route');
        }
        setLoadingProcess(false);
    };

    const handleDeleteRoute = async () => {
        setLoadingProcess(true);
        try {
            const response = await deleteRoute(selectedRoute._id);
            if (response.success) {
                setRoutes(routes.filter(route => route._id !== selectedRoute._id));
                onCloseDeleteModal();
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal(error);
        }
        setLoadingProcess(false);
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="Routes"
                    buttonText={hasPermission('createRoute') ? "Create New Route" : null}
                    onClick={hasPermission('createRoute') ? onOpenCreateModal : null}

                    secondButton={hasPermission('createRoutePickup')}
                    secondButtonText='Add Daily Pickup'
                    secondButtonOnClick={onOpenPickupModal}
                    secondButtonIcon={<FaPlus />}

                    thirdButton={hasPermission('viewRoutePickups')}
                    thirdButtonText='View All Daily Pickup'
                    thirdButtonOnClick={() => navigate(`/routes/routes-pickups`)}
                    thirdButtonIcon={<FaList />}
                />

                {error ? (
                    <AccessDenied msg={error} />
                )
                    :
                    routes.length > 0 ? (
                        <>
                            <HStack>
                                <Button
                                    colorScheme="blue"
                                    onClick={handleViewAllHistory}
                                    isLoading={loadingProcess}
                                    width="fit-content"
                                >
                                    View All Generation History
                                </Button>
                                <GenerateAllRoutesButton />
                            </HStack>

                            <Divider />

                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>#</Th>
                                        <Th>Name</Th>
                                        <Th>Courier</Th>
                                        <Th>Cities</Th>
                                        <Th textAlign="right">Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {routes.map((route, index) => (
                                        <Tr _hover={{ bg: "gray.100" }} key={route._id}>
                                            <Td>{index + 1}</Td>
                                            <Td>{route.name}</Td>
                                            <Td>
                                                <Badge
                                                    colorScheme={route?.courier?.name ? 'green' : 'red'}
                                                    onClick={hasPermission('updateRoute') ? () => { setSelectedRoute(route); onOpenCourierModal(); } : null}
                                                    cursor="pointer"
                                                >
                                                    {route?.courier?.name || "No Courier Assigned"}
                                                </Badge>
                                            </Td>
                                            <Td>{route.cities}</Td>
                                            <Td textAlign="right">
                                                <HStack justifyContent="flex-end" spacing={4}>
                                                    {hasPermission('viewRoutes') &&
                                                        <Button onClick={() => { navigate(`/routes/view/${route._id}`) }} size="sm" colorScheme="blue">
                                                            <ViewIcon mr={2} /> View
                                                        </Button>
                                                    }
                                                    {hasPermission('updateRoute') &&
                                                        <Button onClick={() => { setSelectedRoute(route); onOpenEditModal(); }} size="sm" colorScheme="green">
                                                            <EditIcon mr={2} /> Edit
                                                        </Button>
                                                    }
                                                    {hasPermission('deleteRoute') &&
                                                        <Button onClick={() => { setSelectedRoute(route); onOpenDeleteModal(); }} size="sm" colorScheme="red">
                                                            <DeleteIcon mr={2} /> Delete
                                                        </Button>
                                                    }
                                                    {hasPermission('generateRoute') &&
                                                        <RouteGenerator route={route} />
                                                    }
                                                    <Button
                                                        onClick={() => handleViewRouteHistory(route)}
                                                        size="sm"
                                                        colorScheme="purple"
                                                        isLoading={loadingProcess}
                                                    >
                                                        History
                                                    </Button>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>

                            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={getRoutes} />
                        </>
                    ) : (
                        <AccessDenied msg="Nothing here yet !" icon='info' />
                    )}

                {/* Create Route Modal */}
                <Modal isOpen={isCreateModalOpen} onClose={handleModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create New Route</ModalHeader>
                        {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                        <ModalBody>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input value={newRoute.name} onChange={(e) => setNewRoute({ ...newRoute, name: e.target.value })} />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>Cities</FormLabel>
                                <Input value={newRoute.cities} onChange={(e) => setNewRoute({ ...newRoute, cities: e.target.value })} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={handleModalClose} mr={3}>Cancel</Button>
                            <Button isLoading={loadingProcess} colorScheme="blue" onClick={handleCreateRoute}>Create</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Route Modal */}
                {selectedRoute && (
                    <Modal isOpen={isEditModalOpen} onClose={handleModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Edit Route</ModalHeader>
                            {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                            <ModalBody>
                                <FormControl>
                                    <FormLabel>Name</FormLabel>
                                    <Input value={selectedRoute.name} onChange={(e) => setSelectedRoute({ ...selectedRoute, name: e.target.value })} />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Cities</FormLabel>
                                    <Input value={selectedRoute.cities} onChange={(e) => setSelectedRoute({ ...selectedRoute, cities: e.target.value })} />
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={handleModalClose} mr={3}>Cancel</Button>
                                <Button isLoading={loadingProcess} colorScheme="blue" onClick={handleEditRoute}>Save</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                )}

                {/* Delete Confirmation Modal */}
                <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm Deletion</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete route <strong>{selectedRoute?.name}</strong>?
                        </ModalBody>

                        {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}



                        <ModalFooter>
                            <Button isLoading={loadingProcess} colorScheme="red" onClick={handleDeleteRoute}>Delete</Button>
                            <Button onClick={onCloseDeleteModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>



                {/* Assign Courier Modal */}
                {selectedRoute && (
                    <Modal isOpen={isCourierModalOpen} onClose={onCloseCourierModal}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Assign Courier to Route</ModalHeader>
                            <ModalBody>
                                <FormControl>
                                    <FormLabel>Select Courier</FormLabel>
                                    <Select
                                        placeholder="Select courier"
                                        onChange={(e) => setSelectedCourier(e.target.value)}
                                    >
                                        {couriers.map((courier) => (
                                            <option key={courier._id} value={courier._id}>
                                                {courier.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" onClick={handleAssignCourier} isLoading={loadingProcess}>
                                    Assign Courier
                                </Button>
                                <Button variant="ghost" onClick={onCloseCourierModal}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                )}


                <CreatePickupModal
                    isOpen={isPickupModalOpen}
                    onClose={onClosePickupModal}
                    isRoutePickup={true}
                />
            </VStack>


            {/* Modal for ALL Generation History */}
            <Modal isOpen={isAllHistoryOpen} scrollBehavior='inside' onClose={onCloseAllHistory} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>All Generation History</ModalHeader>
                    <ModalBody>
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Route ID</Th>
                                    <Th>Generated By</Th>
                                    <Th>Date</Th>
                                    <Th>Pickups Count</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {allGenerationHistory.map((history) => (
                                    <Tr key={history._id}>
                                        <Td>{history.routeId?.name || history.routeId?._id}</Td>
                                        <Td>{history.generatedBy?.firstName}</Td>
                                        <Td>{new Date(history.generatedAt).toLocaleDateString()}</Td>
                                        <Td>{history.details?.length}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseAllHistory}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Modal for SINGLE-ROUTE Generation History */}
            <Modal isOpen={isRouteHistoryOpen} scrollBehavior='inside' onClose={onCloseRouteHistory} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Route Generation History: {selectedRoute?.name}</ModalHeader>
                    <ModalBody>
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Date</Th>
                                    <Th>Generated By</Th>
                                    <Th>Pickups Count</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {routeGenerationHistory.map((history) => (
                                    <Tr key={history._id}>
                                        <Td>{new Date(history.generatedAt).toLocaleDateString()}</Td>
                                        <Td>{history.generatedBy?.firstName}</Td>
                                        <Td>{history.details?.length}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseRouteHistory}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>
    );
};

export default AllRoutes;
