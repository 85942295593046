import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, VStack, Text, useToast, Textarea, Heading } from '@chakra-ui/react';
import axios from 'axios';
import { addOrderInternalNote } from '../../api';
import { alert, parseNote } from '../../Utils';
import AccessDenied from '../../components/AccessDenied';

const OrdersInternalNotes = ({ orderId, existingNotes, onNoteAdded, addNote = true }) => {
    const [noteText, setNoteText] = useState('');
    const toast = useToast();

    const handleAddNote = async () => {
        if (!noteText) {
            toast({
                title: "Note text is required.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await addOrderInternalNote(orderId, noteText); // Call the centralized API function
            console.log(response)
            if (response.success) {
                toast({
                    title: "Note added successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                // Clear the input field
                setNoteText('');

                // Call the callback function to update the notes list
                if (onNoteAdded) {
                    onNoteAdded(response.data.internalNotes);
                }
            } else {
                alert("Oops !", 'warning', response.error, 'Okay !')
            }
        } catch (error) {
            toast({
                title: "Failed to add note.",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }

    };

    return (
        <Box>
            {addNote &&
                <VStack spacing={4} align="stretch" mb={5}>
                    <FormControl>
                        <Heading mb={5} size='md' as={'h5'} color="gray.400">Internal Notes</Heading>
                        <Textarea
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                            placeholder="Enter note text"
                            resize="vertical" // Allows resizing of the textarea
                        />
                    </FormControl>
                    <Box alignSelf="flex-end">
                        <Button onClick={handleAddNote} colorScheme="blue" size="sm">
                            Add Note
                        </Button>
                    </Box>
                </VStack>
            }

            <Box mt={4}>
                {existingNotes.length === 0 ? (
                    <AccessDenied msg={"No Internal Notess on this order"} icon='info' />
                ) : (
                    <VStack align="start" spacing={3}>
                        {existingNotes.map((note, index) => (
                            <Box key={index} p={3} borderWidth="1px" borderRadius="md" w="full">
                                <Text fontSize="sm" mt={3}>{parseNote(note.noteText)}</Text>
                                <Text fontSize="xs" mt={5} color="gray.500">
                                    {new Date(note.createdAt).toLocaleString()} by {note?.createdBy?.firstName + " " + note?.createdBy?.lastName}
                                </Text>
                            </Box>
                        ))}
                    </VStack>
                )}
            </Box>
        </Box>
    );
};

export default OrdersInternalNotes;
