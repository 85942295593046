import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { fetchAllRoutes, generateAllRoutes } from '../../api';
import { alert } from '../../Utils';

const GenerateAllRoutesButton = () => {
    const [allRoutes, setAllRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allGeneratedToday, setAllGeneratedToday] = useState(false);

    // Fetch routes on mount
    useEffect(() => {
        const loadRoutes = async () => {
            try {
                const response = await fetchAllRoutes();
                if (response.success) {
                    setAllRoutes(response.data.routes || response.data);
                }
            } catch (error) {
                console.error('Error fetching routes:', error);
            }
        };
        loadRoutes();
    }, []);

    // Check if ALL routes were generated today
    useEffect(() => {
        if (!allRoutes.length) {
            setAllGeneratedToday(false);
            return;
        }

        const now = new Date();

        const allGenerated = allRoutes.every((route) => {
            if (!route.lastGeneration?.date) return false;
            const routeDate = new Date(route.lastGeneration.date);

            return (
                routeDate.getFullYear() === now.getFullYear() &&
                routeDate.getMonth() === now.getMonth() &&
                routeDate.getDate() === now.getDate()
            );
        });

        setAllGeneratedToday(allGenerated);

    }, [allRoutes]);

    // Generate all routes
    const handleGenerateAll = async () => {
        setIsLoading(true);
        try {
            const response = await generateAllRoutes();
            if (response.success) {
                // Re-fetch or update allRoutes
                const newRoutesData = await fetchAllRoutes();
                if (newRoutesData.success) {
                    setAllRoutes(newRoutesData.data.routes || newRoutesData.data);
                }
                setAllGeneratedToday(true)
                alert('Generated', 'success', 'All routes generated successfully !', 'Okay !');
            } else {
                console.error('Failed to generate all routes:', response.message);
            }
        } catch (error) {
            console.error('Error generating all routes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            colorScheme="yellow"
            onClick={handleGenerateAll}
            isLoading={isLoading}
            loadingText="Generating..."
            isDisabled={allGeneratedToday}
            width="fit-content"
            size={"md"}
        >
            {allGeneratedToday ? 'All Routes Generated Today' : 'Generate All Routes'}
        </Button>
    );
};

export default GenerateAllRoutesButton;
