import { useState } from 'react';
import 'react-image-lightbox/style.css'; // Import the Lightbox styles
import Lightbox from 'react-image-lightbox';
import { Image } from '@chakra-ui/react';

const ImageLightbox = ({ imageUploadedAt, imageUploadedBy, imageUrl, thumbnailSize = '150px', altText = 'Image', boxSize }) => {
    const [isOpen, setIsOpen] = useState(false); // State to control the lightbox
    const upAt = new Date(imageUploadedAt).toLocaleString();
    const handleImageClick = () => {
        setIsOpen(true); // Open the lightbox on image click
    };

    return (
        <>
            {/* Thumbnail Image */}
            <Image
                src={imageUrl}
                alt={altText}
                onClick={handleImageClick} // Open lightbox on click
                cursor="pointer"
                mt={4}
                objectFit="cover"
                boxSize={boxSize || thumbnailSize} // Use thumbnailSize prop for dynamic sizing
            />

            {/* Lightbox for large image view */}
            {isOpen && (
                <Lightbox
                    mainSrc={imageUrl}
                    onCloseRequest={() => setIsOpen(false)} // Close the lightbox
                    reactModalStyle={{ overlay: { zIndex: 2000 }, content: { zIndex: 2100 } }} // Override z-index
                    imageTitle={"Attachment Image on pickup"}
                    imageCaption={`Uploaded by ${imageUploadedBy.firstName} | ${upAt}`}

                />
            )}
        </>
    );
};

export default ImageLightbox;
