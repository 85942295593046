import React, { useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    Button, Box, Text, Badge, VStack, Divider, useToast, IconButton
} from "@chakra-ui/react";
import { toggleItemReturn, deleteShipmentItem } from "../../api";
import { FaTrash } from "react-icons/fa";

const ViewShipmentModal = ({ isOpen, onClose, shipment, refreshShipments }) => {
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    if (!shipment) return null;

    const handleToggleReturn = async (itemId, currentStatus) => {
        setLoading(true);
        const response = await toggleItemReturn(shipment._id, itemId, !currentStatus);
        if (response.success) {
            toast({ title: `Item marked as ${!currentStatus ? "Returned" : "Not Returned"}`, status: "success" });
            refreshShipments();
            // onClose();
        } else {
            toast({ title: "Failed to update item", status: "error" });
        }
        setLoading(false);
    };

    const handleDeleteItem = async (itemId) => {
        setLoading(true);
        const response = await deleteShipmentItem(shipment._id, itemId);
        if (response.success) {
            toast({ title: "Item deleted successfully", status: "success" });
            refreshShipments();
            onClose();
        } else {
            toast({ title: "Failed to delete item", status: "error" });
        }
        setLoading(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Shipment Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* Client Details */}
                    <Box mb={4}>
                        <Text fontWeight="bold">Client:</Text>
                        <Text>{shipment.clientName}</Text>
                        <Text fontWeight="bold">Address:</Text>
                        <Text>{shipment.clientAddress || "N/A"}</Text>
                    </Box>

                    <Divider />

                    {/* Sent Items */}
                    <Box mt={4}>
                        <Text fontWeight="bold">Items Sent:</Text>
                        <VStack align="start" spacing={2} mt={2}>
                            {shipment.items.map((item, index) => (
                                <Box key={index} p={2} border="1px solid" borderRadius="md" w="full" display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Text><strong>{item.equipmentName}</strong></Text>
                                        <Text>Serial: {item.serialNumber}</Text>
                                        <Badge colorScheme={item.returned ? "green" : "red"}>
                                            {item.returned ? "Returned" : "Not Returned"}
                                        </Badge>
                                    </Box>
                                    <Box>
                                        <Button size="sm" isLoading={loading} onClick={() => handleToggleReturn(item._id, item.returned)}>
                                            {item.returned ? "Mark as Not Returned" : "Mark as Returned"}
                                        </Button>
                                        <IconButton
                                            ml={2}
                                            size="sm"
                                            colorScheme="red"
                                            icon={<FaTrash />}
                                            isLoading={loading}
                                            onClick={() => handleDeleteItem(item._id)}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    <Divider />

                    {/* Notes */}
                    <Box mt={4}>
                        <Text fontWeight="bold">Notes:</Text>
                        {shipment.notes.length > 0 ? (
                            <VStack align="start" spacing={2} mt={2}>
                                {shipment.notes.map((note, index) => (
                                    <Box key={index} p={2} border="1px solid" borderRadius="md" w="full">
                                        <Text>{note.text}</Text>
                                        <Text fontSize="sm" color="gray.500">
                                            Added by {note.createdBy} on {new Date(note.createdAt).toLocaleString()}
                                        </Text>
                                    </Box>
                                ))}
                            </VStack>
                        ) : (
                            <Text color="gray.500">No notes available</Text>
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ViewShipmentModal;
