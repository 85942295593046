import React, { useRef, useState } from "react";
import { Button, Textarea } from "@chakra-ui/react";
import { darkLogo } from "../../Utils";

const SaveAsPDF = ({ clientName }) => {
  const printRef = useRef();
  const [boxContent, setBoxContent] = useState("");

  const handlePrintBrowser = () => {
    const printContent = printRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @page {
              size: 6in 4in landscape;
              margin: 0;
            }
            body {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              font-family: Arial, sans-serif;
            }
            .print-container {
              width: 6in;
              height: 4in;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border: 1px solid black;
              background-color: #f9f9f9;
            }
          </style>
        </head>
        <body>
          <div class="print-container">${printContent}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div>
      <div
        ref={printRef}
        style={{
          width: "6in", // Adjust for landscape width
          height: "4in", // Adjust for landscape height
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid #ccc",
          justifyContent: "center",
        }}
      >
        <img
          src={darkLogo}
          alt="DLS"
          style={{ marginBottom: "10px", maxWidth: "45%" }}
        />
        <h2 style={{ fontWeight: "bold", textAlign: 'center', fontSize: 25 }}>{clientName}</h2>
        {boxContent &&
          <h2 style={{ fontWeight: "bold", textAlign: 'center', width: "100%", marginTop: '10px', fontSize: 20 }}>{boxContent}</h2>
        }
      </div>

      <div style={{ marginTop: "20px" }}>

        <Textarea
          placeholder="Route or anything else to write"
          value={boxContent}
          onChange={(e) => setBoxContent(e.target.value)}
          rows={4}
          mb={4}
          w="100%"
        />

        {/*         
        <textarea
          placeholder="Route# "
          value={boxContent}
          onChange={(e) => setBoxContent(e.target.value)}
          rows={4}
          style={{ display: "block", marginBottom: "10px", width: "100%" }}
        ></textarea> */}
        <Button colorScheme="blue" onClick={handlePrintBrowser}> Print </Button>
        {/* <button onClick={handlePrintBrowser}>Print</button> */}
      </div>
    </div>
  );
};

export default SaveAsPDF;
